import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import en from './locales/en'
import ar from './locales/ar'
import 'vue-select/dist/vue-select.css';


import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

import Popover from 'vue-js-popover'
Vue.use(Popover)

import VueHead from 'vue-head'
Vue.use(VueHead)



import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    en,
    ar
};

const i18n = new VueI18n({
    locale: localStorage.getItem("deb-locale") || 'ar', // set locale
    messages
});

import Tooltip from 'hsy-vue-tooltip'
Vue.use(Tooltip)

import VueFormulate from "@braid/vue-formulate";
Vue.use(VueFormulate);

import vClickOutside from 'v-click-outside'
Vue.use(vClickOutside)

import Unicon from 'vue-unicons/dist/vue-unicons-vue2.umd'
import {
    uniTwitter,
    uniSnapchatGhost,
    uniFacebook,
    uniAngleLeftB,
    uniMapMarker,
    uniAngleDown,
    uniMultiply,
    uniBars,
    uniSignin,
    uniUser,
    uniTimes,
    uniMap,
    uniAngleRight,
    uniAngleLeft,
    uniHeartSign,
    uniCommentDots,
    uniCog,
    uniSignOutAlt,
    uniPhone,
    uniWhatsapp,
    uniEye,
    uniKeySkeletonAlt,
    uniSync,
    uniRedo,
    uniSearch
} from 'vue-unicons/dist/icons'
Unicon.add([uniTwitter, uniSnapchatGhost, uniFacebook, uniAngleLeftB, uniMapMarker, uniAngleDown, uniMultiply, uniBars, uniSignin, uniUser, uniTimes, uniMap, uniAngleRight, uniAngleLeft, uniHeartSign, uniCommentDots, uniCog, uniSignOutAlt, uniPhone, uniWhatsapp, uniEye, uniKeySkeletonAlt, uniSync, uniRedo, uniSearch])
Vue.use(Unicon)

// import VueGoogleMap from 'vuejs-google-maps'
// Vue.use(VueGoogleMap, {
//   load: {
//     apiKey: 'AIzaSyCsE5KDJqjPpbTHsQFqSjnJHclQuCBw8c4',
//     libraries: [ /* rest of libraries */ ]
//   }
// })



import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);

import VModal from 'vue-js-modal'
Vue.use(VModal)

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

import VueLazyload from 'vue-lazyload'

// or with options
//const loadimage = require('./assets/logos/LOGO1.png')
const loadimage = `https://picsum.photos/10/6?image=${1 * 5 + 10}`

Vue.use(VueLazyload, {
    preLoad: 1.3,
    loading: loadimage,
    attempt: 1
})

import loading from 'vuejs-loading-screen'

Vue.use(loading, {
    bg: '#1B47EA',
    icon: 'refresh',
    size: 3,
    icon_color: 'white',
})

Vue.config.productionTip = false
new Vue({
    router,
    store,
    i18n,

    render: h => h(App)
}).$mount('#app')