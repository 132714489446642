<template>
  <section class="top-nav-bar">
    <!-- <div class="logo-right">
      <img src="@/assets/logos/REGA.png" alt="" class="logo-right-img" />
    </div> -->
    <div class="row">
      <div class="col-5">
        <div class="logo-right">
          <img src="@/assets/logos/REGA.png" alt="" class="logo-right-img" />
          <span> مصنف من الهيئة العامة للعقار رقم التصنيف: 253 </span>
          <!-- <button>سداد</button> -->
        </div>
      </div>
      <div class="col-2">
        <div class="top-bar-date">
          <span class="date-day"> {{ getDay(current_date) }}</span>
          <span class="date-month"> {{ dateFormat(current_date) }} </span>
        </div>
      </div>
      <div class="col-5">
        <div class="icons-side">
          <div class="phone-location">
            <div class="phone">
              <div class="icon">
                <img src="@/assets/icons/website/phone.svg" alt="" />
              </div>
              <div class="text">0163831111</div>
            </div>
            <div class="phone">
              <div class="icon">
                <img src="@/assets/icons/website/location.svg" alt="" />
              </div>
              <div class="text">
                {{ info.workingTime.content }}
                <!-- اوقات العمل : من السبت إلى الخميس 8:00 ص - 12:00 م 4:00 م - 9:00 م -->
              </div>
            </div>
          </div>
          <div class="social-icons">
            <ul>
              <li>
                <a
                  href="https://www.linkedin.com/in/%D8%B4%D8%B1%D9%83%D8%A9-%D8%A7%D9%84%D8%B6%D8%A8%D8%B9%D8%A7%D9%86-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D9%8A%D8%A9-35486a166/?originalSubdomain=sa"
                  target="_blank"
                >
                  <span class="item-icon">
                    <img src="@/assets/icons/website/linkedin.svg" alt="" />
                  </span>
                </a>
              </li>
              <li>
                <!-- <router-link to="/"> -->
                <span class="item-icon">
                  <img
                    src="@/assets/icons/website/whatsapp.svg"
                    alt=""
                    @click="sendByWhatsapp"
                  />
                </span>
                <!-- </router-link> -->
              </li>
              <li>
                <a href="https://www.instagram.com/"
                  ><span class="item-icon">
                    <img src="@/assets/icons/website/instgram.png" alt="" />
                  </span>
                </a>
              </li>
              <li>
                <a href="https://twitter.com/aldabaan_sa" target="_blank">
                  <span class="item-icon">
                    <img src="@/assets/icons/website/email.png" alt="" />
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      current_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
    };
  },
  computed: {
    ...mapGetters("EstateIndex", ["info"]),
  },
  methods: {
    sendByWhatsapp() {
      var phone = "966500465646";
      var msg = "السلام عليكم مسوق شركة الضبعان العقارية";
      window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${msg}`);
    },

    dateFormat($item) {
      var date = new Date($item);
      return this.format(date);
    },

    format(date) {
      var month = date.toLocaleString("ar-EG", {
        month: "short",
      });

      return month;
    },

    getDay($item) {
      var date = new Date($item);
      return date.getDate() + " ";
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar-date {
  display: flex;
  flex-direction: column;
  .date-day {
    color: #fff;
    text-align: center;
    font-size: 1.9em;
  }

  .date-month {
    color: #fff;
    text-align: center;
    font-size: 13px;
  }
}

.col {
  padding: 20px 0;
  height: 100px;
}

.top-nav-bar {
  padding: 15px;
  background-color: #191e32;
  padding-top: 10px;

  .row {
    align-items: center;
  }

  .button-side {
    text-align: end;

    button {
      background-color: #363b52;
      font-weight: bold;
      color: white;
      padding: 3px 20px;
      border-radius: 3px;
      border: none;
    }
  }

  .icons-side {
    .phone-location {
      direction: ltr;
      margin-left: 30px;
      display: flex;

      .phone {
        display: flex;
        align-items: center;
        margin-left: 10px;

        .icon {
          height: 25px;
          width: 25px;

          img {
            height: 100%;
            width: 100%;
          }
        }

        .text {
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }

    display: flex;
    color: white;
    align-items: center;
    justify-content: flex-end;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;

      li {
        margin-left: 10px;
        height: 30px;
        width: 30px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}

.text {
  p {
    margin: 0 !important;
  }
}

.logo-right {
  display: flex;
  align-items: center;

  button {
    margin-right: 100px;
    background-color: #363b52;
    font-weight: bold;
    color: white;
    padding: 3px 20px;
    border-radius: 3px;
    border: none;
  }

  span {
    color: white;
    font-size: 14px;
  }

  .logo-right-img {
    width: 120px;
  }
}
</style>
