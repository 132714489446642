<template>
  <div class="test">
    
    <div class="marquee-container" v-if="info.showMovedText.show">
      <VTextMarquee
        :speed="50"
        :animate="true"
        class="moving-text"
        :rtl="locale == 'ar' ? true : false"
      >
         
        <h4
          v-for="(item, index) in lists.moved_text"
          :key="item.id"
          
          class="moved-text"
        >
        
        {{item.content}}
        <span class="moved-text-img" v-if="!(lists.moved_text.length == index + 1)">
          <img src="@/assets/logos/LOGO1.png" alt="" />
        </span>
        </h4>
       
      </VTextMarquee>
    </div>

    <SearchBar class="search" />

    <div
      class="body"
      :class="{
        'top-no-marquee': !info.showMovedText.show,
        'top-marquee': info.showMovedText.show,
      }"
    >
      <div class="main">
        <div class="content">
          <div
            class="content-right col-xl-5 col-lg-6 col-md-6 col-sm-12"
            :class="{ show: cardsMode }"
          >
            <Cards />
          </div>
          <div
            class="content-left col-xl-7 col-lg-6 col-md-6 col-sm-12"
            :class="{ show: !cardsMode }"
          >
            <HomeMap />
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-map-card-container">
      <div class="mobile-map-card" @click="cardsMode = !cardsMode">
        <span class="text" v-if="cardsMode">
          {{ $t("home.showMap") }}
        </span>
        <span class="text" v-if="!cardsMode">
          {{ $t("home.showEstates") }}
        </span>
        <span class="icon">
          <unicon name="map" fill="#1B47EA" height="18" width="18"></unicon>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { VTextMarquee } from "vue-text-marquee";

import SearchBar from "@/components/SearchBar.vue";
import Cards from "@/components/CardsComponent.vue";
import EstateModal from "@/components/EstateModal.vue";
import AddEstateModal from "@/components/AddEstateModal";
import HomeMap from "@/components/HomeMap.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  metaInfo() {
    return {
      meta: [
      
        // {
        //   property: "og:image",
        //   content: "https://aldabaan.sa/logo-250x250.jpeg",
        // },
        {name:"twitter:card" ,content:"summary"},
      ],

    };
  },
  components: {
    SearchBar,
    Cards,
    HomeMap,
    VTextMarquee,
  },

  data() {
    return {
      center: {
        lat: 24.8184709,
        lng: 46.8028893,
        z: 20,
      },
      cardsMode: true,
      showMovingText: true,
      locale: localStorage.getItem("deb-locale") || "ar",
    };
  },
  computed: {
    ...mapGetters("EstateIndex", ["lists", "info"]),
  },

  methods: {
    ...mapActions("EstateIndex", [
      "getAssetTypes",
      "setAssetsLandPage",
      "setRegions",
      "getAllcities",
      "getDistricts",
      "setAssetCordinate",
      "setMovedTexts",
      "setShowMovedTexts",
      "setAboutUs",
      "setWorkingTime",
    ]),
    setAssetsData(){
      this.setAssetsLandPage().then(()=>{
          this.info.loading = false
      })
    },
  },
  mounted() {
    this.setAssetCordinate();
    this.setAssetsData();
    this.getAssetTypes();
    this.setRegions();
    this.setMovedTexts();
    this.setShowMovedTexts();
    this.setAboutUs();
    this.setWorkingTime();
    // this.$isLoading(true)
    var screenWidth = window.screen.width;
    if (this.$route.name == "details") {
      this.$modal.show(
        EstateModal,
        {},
        {
          width: screenWidth < 992 ? "100%" : "1200px",
          height: "auto",
          scrollable: true,
          name: "detailsModal",
        },

        {
          "before-close": () => {
            this.$router.push({
              path: "/",
            });
          },
        }
      );
    } else if (this.$route.name == "add") {
      this.$modal.show(
        AddEstateModal,
        {},
        {
          width: screenWidth < 992 ? "100%" : "900px",
          height: "auto",
          scrollable: true,
          name: "addModal",
        },

        {
          "before-close": () => {
            this.$router.push({
              path: "/",
            });
          },
        }
      );
    } else {
      this.$modal.hideAll();
    }
  },
  watch: {
    $route(to) {
      if (to.name != "details" && to.name != "add") {
        this.$modal.hideAll();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.test {
  .home-mobile-nav {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .test {
    .content-left {
      display: none;
    }
    .content-right {
      display: none;
    }
    .show {
      display: block !important;
    }
    .hide {
      display: none !important;
    }

    .body {
      position: absolute;
      top: 158px !important;
      right: 0;
      bottom: 0;
      left: 0;
    }
    .marquee-container {
      display: none;
    }
  }

  .test {
    .mobile-map-card-container {
      display: block;
    }
  }
}

@media (max-width: 992px) {
  .test {
    .top,
    .nav {
      display: none;
    }

    .home-mobile-nav {
      display: block;
    }
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1b47ea80;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

#map {
  height: 100%;
  width: 100%;
  border-right: 1px solid #ccc;

  .map-view {
    height: 100%;
  }
}

.body {
  position: absolute;

  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
}
.top-no-marquee {
  top: 225px;
}
.top-marquee {
  top: 290px;
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  display: flex;
  /* important to set auto or hidden */
  overflow: auto;
}

.content-left {
  width: 100%;
  display: flex;
  box-shadow: 7px 0px 8px -3px rgba(209,205,209,1);
  flex-direction: column;
  overflow: hidden;
  //    width: 400px;
  width: 100%;

  div {
    height: 100%;
    width: 100%;
    // background: green;
  }
}

.content-right {
  width: 100%;
  flex: 1;
  overflow: auto;
  word-break: break-all;
  padding: 6px 0px;
  
  direction: ltr;
}

.column {
  padding: 20px;
  border-right: 1px solid #999;
}

.column > div {
  background: red;
  width: 300px;
}

.mobile-map-card-container {
  display: none;
}

.mobile-map-card {
  position: absolute;
  margin: auto;
  left: 35%;
  box-shadow: 0 0 7px 1px rgb(0 0 0 / 25%);
  border-radius: 4px;
  bottom: 10px;
  background: #e0f2fd;
  color: $primary;
  padding: 10px;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  .text,
  .icon {
    margin-left: 5px;
  }
}
.moving-text {
  color: $text;
  background-color: white;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  direction: ltr;
}
.moved-text {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
  color: #757575;
}
.moved-text-img {
  img {
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
</style>
