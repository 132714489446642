<template>
  <section class="home-map">
    <!-- <google-map id="map" ref="Map" :center="center" :zoom="10"> </google-map> -->
    <div id="mapa"></div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import EstateModal from "./EstateModal.vue";
export default {
  data() {
    return {
      map: undefined,
      markersArray: [],
      center: {
        lat: 26.3479206,
        lng: 43.7833417,
        z: 40,
      },
      svg: window.btoa(`
                <svg fill="#edba31" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity="1" r="70" />
                <circle cx="120" cy="120" opacity=".7" r="90" />
                <circle cx="120" cy="120" opacity=".3" r="110" />
                <circle cx="120" cy="120" opacity=".2" r="130" />
                </svg>`),
      svg2: window.btoa(`
                <svg fill="#1B47EA" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                <circle cx="120" cy="120" opacity="1" r="70" />
                <circle cx="120" cy="120" opacity=".7" r="90" />
                <circle cx="120" cy="120" opacity=".3" r="110" />     
                </svg>`),
      mcOptions: {
        styles: [
          {
            height: 53,
            url: "https://github.com/googlemaps/js-marker-clusterer/tree/gh-pages/images/m1.png",
            width: 53,
          },
          {
            height: 56,
            url: "https://github.com/googlemaps/js-marker-clusterer/tree/gh-pages/images/m2.png",
            width: 56,
          },
          {
            height: 66,
            url: "https://github.com/googlemaps/js-marker-clusterer/tree/gh-pages/images/m3.png",
            width: 66,
          },
          {
            height: 78,
            url: "https://github.com/googlemaps/js-marker-clusterer/tree/gh-pages/images/m4.png",
            width: 78,
          },
          {
            height: 90,
            url: "https://github.com/googlemaps/js-marker-clusterer/tree/gh-pages/images/m5.png",
            width: 90,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("EstateIndex", [
      "lists",
      "info",
      "loading",
      "position",
      "MapData",
      "markers",
    ]),
    dataList() {
      //   console.log(this.$store.getters["EstateIndex/lists"].dataSet);
      return this.$store.getters;
    },
  },
  watch: {
    "lists.coordinates": {
      handler: function (val) {
        this.deleteMarkers();
        for (var item of val) {
          this.createMarker(item);
        }
        const renderer = {
          render: ({ count, position }) =>
            new window.google.maps.Marker({
              label: {
                text: String(count),
                color: "#ffffff",
                fontSize: "14px",
                fontWeight: "600",
              },
              icon: {
                url: `data:image/svg+xml;base64,${this.svg2}`,
                scaledSize: new window.google.maps.Size(45, 45),
              },
              position,
              // adjust zIndex to be above other markers
              zIndex: Number(window.google.maps.Marker.MAX_ZINDEX) + count,
            }),
        };
        new MarkerClusterer({
          markers: this.markers,
          map: this.map,
          renderer,
        });
      },
      deep: true,
    },
    position: {
      handler: function (val) {
        this.map.setCenter(val);
        this.map.setZoom(15);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions("EstateIndex", ["searchByLocation", "performSearch"]),
    ...mapMutations("EstateIndex", ["addMarker", "deleteStateMarkers"]),

    initMap() {
      this.map = new window.google.maps.Map(document.getElementById("mapa"), {
        center: this.position,
        zoom: 10,
        styles: [
          {
            featureType: "administrative",
            elementType: "geometry",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "transit",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      });

      this.map.addListener("zoom_changed", () => {
        this.searchByLocation(this.map.center);
      });

      this.map.addListener("dragend", () => {
        this.searchByLocation(this.map.center);
      });
    },

    getPositions(r) {
      return {
        lat: parseFloat(r.lat),
        lng: parseFloat(r.lng),
      };
    },

    createMarker(item) {
      var icon = {
        url: this.getMarkerIcon(item.offer_type), // url
        scaledSize: new window.google.maps.Size(25, 25), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(0, 0), // anchor
      };

      var marker = new window.google.maps.Marker({
        position: this.getPositions(item),
        map: this.map,
        icon,
        title: item.asset_type,
        id: item.asset_id,
        offerType: item.offer_type,
      });

      this.addMarker(marker);

      window.google.maps.event.addListener(marker, "click", () => {
        this.showDetailsModal(item);
      });

      var showPrice = item.offer_price || "لم يتم تعيين سعر";

      const contentString =
        '<div class="info-card">' +
        '<div class="text-side">' +
        `<div>
        ${showPrice} 
        </div>` +
        `<div>
        ${item.asset_type}</div>` +
        "</div>" +
        '<div class="img-side">' +
        `<div class="img" style="background-image: url(${item.image_url})"> </div>` +
        "</div>" +
        "</div>";

      const infowindow = new window.google.maps.InfoWindow({
        content: contentString,
      });

      marker.addListener("mouseover", () => {
        var icon = {
          url: this.getMarkerIcon("hover"), // url
          scaledSize: new window.google.maps.Size(30, 30), // scaled size
          origin: new window.google.maps.Point(0, 0), // origin
          anchor: new window.google.maps.Point(0, 0), // anchor
        };

        // var svgMarker = {
        //   path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        //   fillColor: "green",
        //   fillOpacity: 1,
        //   strokeWeight: 0,
        //   rotation: 0,
        //   scale: 0.4,
        // };
        marker.setIcon(icon);
        infowindow.open({
          anchor: marker,
          map: this.map,
          shouldFocus: false,
        });
      });

      // assuming you also want to hide the infowindow when user mouses-out
      marker.addListener("mouseout", () => {
        var icon = {
          url: this.getMarkerIcon(marker.offerType), // url
          scaledSize: new window.google.maps.Size(30, 30), // scaled size
          origin: new window.google.maps.Point(0, 0), // origin
          anchor: new window.google.maps.Point(0, 0), // anchor
        };
        // var svgMarker = {
        //   path: "M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z",
        //   fillColor: this.getColorByOfferType(marker.offerType),
        //   fillOpacity: 1,
        //   strokeWeight: 0,
        //   rotation: 0,
        //   scale: 0.4,
        // };
        marker.setIcon(icon);
        infowindow.close();
      });

      return marker;
    },

    setMapOnAll(map) {
      for (var i = 0; i < this.markers.length; i++) {
        this.markers[i].setMap(map);
      }
    },
    clearMarkers() {
      this.setMapOnAll(null);
    },
    deleteMarkers() {
      this.clearMarkers();
      //this.map.setZoom(item);
      this.deleteStateMarkers();
      //this.markers = [];
    },

    getMarkerColor(item) {
      if (item.offer_type == "بيع") return "red";
      else if (item.offer_type == "إيجار") return "#4A148C";
      else return "#673AB7";
    },

    getMarkerIcon(offer_type) {
      if (offer_type == "بيع")
        return "https://aldabaan.sa/markers/Untitled-112.png";
      else if (offer_type == "إيجار")
        return "https://aldabaan.sa/markers/Untitled-123.png";
      else if (offer_type == "hover")
        return "https://aldabaan.sa/markers/Untitled-111.png";
      else return "https://aldabaan.sa/markers/Untitled-1578.png";
    },
    getColorByOfferType(offer_type) {
      if (offer_type == "بيع") return "red";
      else if (offer_type == "إيجار") return "#4A148C";
      else return "#673AB7";
    },
    showDetailsModal(item) {
      var screenWidth = window.screen.width;
      this.$router.push({
        path: "/details/" + item.asset_id,
      });

      this.$modal.show(
        EstateModal,
        {},
        {
          width: screenWidth < 992 ? "100%" : "1200px",
          height: "auto",
          scrollable: false,
        },
        {
          "before-close": () => {
            this.$router.push({
              path: "/",
            });
          },
        }
      );
    },
  },
  mounted() {
    let initializeWhenGoogleIsAvailable = () => {
      if (window.google) {
        this.initMap();
      } else {
        setTimeout(initializeWhenGoogleIsAvailable, 100);
      }
    };
    initializeWhenGoogleIsAvailable();
  },
  created() {
    //   this.initMap();
  },
};
</script>

<style lang="scss">
.home-map {
  height: 100%;
  width: 100%;
  position: relative;
}

#mapa {
  height: 100%;
  width: 100%;
  border-right: 1px solid rgb(204, 204, 204);

  .map-view {
    height: 100%;
  }
}

#mapa .gm-style-iw-d {
  padding: 0;
  overflow: hidden !important;
}

#mapa .gm-style-iw-c {
  padding: 0;
}

#mapa .gm-style-iw {
  box-shadow: none;
  border-radius: 0;
  width: 140px;

  .info-card {
    width: 100%;
    padding: 10px;
    font-family: "Tajawal", sans-serif;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .text-side {
      font-weight: bold;
      margin-left: 5px;
    }

    .img-side {
      height: 80px;
      width: 50px;

      .img {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
}
</style>
