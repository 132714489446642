import {
    plainAxiosInstance
} from '../../axios/index';

import router from '../../router'



const state = {
    credentials: {},
    addValues: {},
    lists: {
        allNationlaties: [],
        cities : [] ,
        regions : []
    },
    info: {
        validation_error: ''
    },
    loading: false,
    wrongCreds: false,
    apiError: false,
    auth_user: JSON.parse(localStorage.getItem("deb-us")),
    isLoggedin: false,
    baseUrl: "https://aldabaan.sa",
    contactValues: {},
    joinValues: {},
    evaluteValues: {}
}


const getters = {

    loading: state => state.loading,
    wrongCreds: state => state.wrongCreds,
    apiError: state => state.apiError,
    user: state => state.auth_user,
    isLoggedin: state => state.isLoggedin,
    baseUrl: state => state.baseUrl,
    addValues: state => state.addValues,
    lists: state => state.lists,
    info: state => state.info,
    contactValues: state => state.contactValues,
    joinValues: state => state.joinValues,
    evaluteValues: state => state.evaluteValues
}

const actions = {

    async login({
        state,
        commit,
        dispatch
    }) {
        commit('setWrongCreds', false)
        return new Promise((resolve, reject) => {
            commit('setLoading', true)
            plainAxiosInstance
                .post('user_login', state.credentials)
                .then(response => {
                    // 1036114393
                    // 12301230
                    commit('setLoading', false)
                    localStorage.setItem("deb-to", response.data.access_token);
                    localStorage.setItem("deb-us", JSON.stringify(response.data.user));
                    state.isLoggedin = true
                    router.push({
                        path: "/"
                    });
                    dispatch('Alert/successMsg', {
                        msg: 'تم تسجيل الدخول بنجاح'
                    }, {
                        root: true
                    })

                    // this.$forceUpdate();
                    commit('setUser', response.data.user)
                    resolve(response)

                })
                .catch(error => {
                    if (error.response.status == 401) {
                        commit('setWrongCreds', true)
                    } else {
                        commit('setApiError', true)
                    }

                    reject(error)
                    commit('setLoading', false)

                })
        })

    },

    setNationalties({
        commit
    }) {

        plainAxiosInstance.get("get_all_nationalites")
            .then(response => {

                commit('setNationalties', response.data)
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    validateNumberUniqe({
        state
    }, phone) {
       
        plainAxiosInstance.post('validate_phone', {
            phone: phone
        }).then(response => {
            if (!response.data.validated) {
                state.info.validation_error = 'رقم الجوال مضاف من قبل'
            } else state.info.validation_error = ''
        })
    },

    async likeAsset({
        state,
        commit,
        dispatch
    }, asset_id) {
        let pram = {
            user_id: state.auth_user.id,
            asset_id: asset_id
        }
        return new Promise((resolve, reject) => {
            commit('setLoading', true)
            plainAxiosInstance
                .post('like_asset', pram)
                .then(response => {
                    commit('setLoading', false)
                    if (response.data.res) {
                        dispatch('EstateIndex/setAssetsLandPage', '', {
                            root: true
                        })
                        dispatch('Alert/successMsg', {
                            msg: 'تم إضافةالعقار إلى قائمة مفضلاتك'
                        }, {
                            root: true
                        })
                    }


                    resolve(response)

                })
                .catch(error => {
                    console.log(error.response)
                    reject(error)
                    commit('setLoading', false)

                })
        })

    },


    async contactUs({
        state,
        commit,
        dispatch
    }) {

        return new Promise((resolve, reject) => {
            commit('setLoading', true)
            plainAxiosInstance
                .post('save_contact_us', state.contactValues)
                .then(response => {
                    commit('setLoading', false)
                    if (response.data.status == 'success') {
                        dispatch('Alert/successMsg', {
                            msg: ''
                        }, {
                            root: true
                        })
                        state.contactValues = {}
                    }


                    resolve(response)

                })
                .catch(error => {
                    console.log(error.response)
                    reject(error)
                    commit('setLoading', false)

                })
        })

    },


    async joinUs({
        state,
        commit,
        dispatch
    }) {

        return new Promise((resolve, reject) => {
            commit('setLoading', true)
            plainAxiosInstance
                .post('save_join_us', state.joinValues)
                .then(response => {
                    commit('setLoading', false)
                    if (response.data.status == 'success') {
                        dispatch('Alert/successMsg', {
                            msg: ''
                        }, {
                            root: true
                        })
                        state.joinValues = {}
                    }


                    resolve(response)

                })
                .catch(error => {
                    console.log(error.response)
                    reject(error)
                    commit('setLoading', false)

                })
        })

    },

    async evaluateRequest({
        state,
        commit,
        dispatch
    }) {

        return new Promise((resolve, reject) => {
            commit('setLoading', true)
            plainAxiosInstance
                .post('save_evaluate_request', state.evaluteValues)
                .then(response => {
                    commit('setLoading', false)
                    if (response.data.status == 'success') {
                        dispatch('Alert/successMsg', {
                            msg: ''
                        }, {
                            root: true
                        })
                        state.evaluteValues = {}
                    }
                    resolve(response)

                })
                .catch(error => {
                    console.log(error.response)
                    reject(error)
                    commit('setLoading', false)

                })
        })

    },

    setRegions({
        commit
    }) {
        plainAxiosInstance
            .get("get_regions")
            .then(response => {

                commit('setRegions', response.data)
            })
            .catch(err => {
                console.log(err.response);
            });
    },

    setCities({
        commit,
    }, region_id) {

        if (region_id != null) {
            plainAxiosInstance
                .get("region_cities/" + region_id)
                .then(response => {
                    commit('setCities', response.data);
                })
                .catch(err => {
                    console.log(err.response);
                });
        } else {
            commit('setCities', []);
            commit('setDistricts', []);
        }

    },



    async dislikeAsset({
        state,
        commit,
        dispatch
    }, asset_id) {
        let pram = {
            user_id: state.auth_user.id,
            asset_id: asset_id
        }
        return new Promise((resolve, reject) => {
            commit('setLoading', true)
            plainAxiosInstance
                .post('dislike_asset', pram)
                .then(response => {
                    
                    commit('setLoading', false)
                    if (response.data.res){
                        dispatch('EstateIndex/setAssetsLandPage' , '' , {root: true})
                        dispatch('Alert/successMsg', {
                            msg: 'تم حذف العقار من المفضلة'
                        }, {
                            root: true
                        })
                    }
                        
                    resolve(response)

                })
                .catch(error => {
                    console.log(error.response)
                    reject(error)
                    commit('setLoading', false)

                })
        })

    },



    async register({
        commit,
        state,
        dispatch
    }) {
        commit('setLoading', true)
        return new Promise((resolve, reject) => {
            plainAxiosInstance
                .post('user', state.addValues)
                .then(response => {
                    
                    if (response.data.status == "success") {
                        state.addValues = {}
                        router.push({
                            path: "/login"
                        });
                        dispatch('Alert/successMsg', {
                            msg: 'تم التسجيل بنجاح'
                        }, {
                            root: true
                        })
                        commit('setLoading', false)
                        resolve(response)

                    } else {
                        commit('setLoading', false)
                        let massage = response.data.title
                        dispatch('Alert/errorMsg', {
                            msg: massage
                        }, {
                            root: true
                        })
                    }


                })
                .catch(error => {
                    reject(error)
                    dispatch('Alert/errorMsg', '', {
                        root: true
                    })
                })

        })
    },





}

const mutations = {

    setLoading(state, loading) {
        state.loading = loading
    },

    setCredentials(state, credentials) {
        state.credentials = credentials
    },
    setUser(state, user) {
        state.auth_user = user
    },
    setWrongCreds(state, wrongCreds) {
        state.wrongCreds = wrongCreds
    },
    setRegions(state, data) {
        state.lists.regions = data
    },
    setCities(state, data) {
        state.lists.cities = data
    },
    setApiError(state, apiError) {
        state.apiError = apiError
    },

    setNationalties(state, data) {
        state.lists.allNationlaties = data
    }
}

// router.beforeEach((to, from, next) => {

//     if (state.isLoggedin) {
//         return next('/');
//     }
//     next()
// })

export default {

    namespaced: true,
    state,
    getters,
    actions,
    mutations

}