<template>
<section class="mobile-search-modal">
    <div class="heading">
        <!-- <button class="btn-primary" @click="closeModal">تم</button> -->
        <div class="heading-close" @click="closeModal">
            <unicon name="multiply" fill="#7DA2C5" height="18" width="18" class="close-btn"></unicon>
        </div>
        <div class="heading-text">
            <h2>بحث متقدم</h2>
        </div>
        <div class="heading-close" @click="resetSearchData">
            <unicon name="redo" fill="#7DA2C5" height="18" width="18" class="close-btn"></unicon>

        </div>

    </div>
    <div class="mobile-drop-down">
        <loading-overlay color="#1B47EA" :active.sync="loading" :is-full-page="true" :can-cancel="true">
        </loading-overlay>
        <div class="mobile-drop-down-body">
            <div class="group">
                <div class="group-items colu">
                    <label for="">نوع العقار</label>
                    <v-select @input="setProperies" v-model="searchValues.asset.asset_type_id" :options="formatOptions(lists.asset_types)" />
                </div>
            </div>

            <div class="group row">
                <div class="col-4">
                    <div class="group-heading">تصنيف العقار</div>
                    <div class="group-items">
                        <FormulateInput v-model="searchValues.asset.classfy_asset" class="radios" :options="{
                  سكني: 'سكني',
                  تجاري: 'تجاري',
                  زراعي: 'زراعي',
                  صناعي: 'صناعي',
                  'تجاري و سكني': 'تجاري و سكني',
                }" type="radio" />
                    </div>
                </div>

                <div class="col-4">
                    <div class="group-heading">نوع الاستعمال</div>
                    <div class="group-items">
                        <FormulateInput v-model="searchValues.asset.usege_type" class="radios" :options="{
                  عزاب: 'عزاب',
                  عوائل: 'عوائل',
                  مكتبي: 'مكتبي',
                  'عزاب و عوائل': 'عزاب و عوائل',
                }" type="radio" />
                    </div>
                </div>

                <div class="col-4">
                    <div class="group-heading">نوع العرض</div>
                    <div class="group-items">
                        <FormulateInput v-model="searchValues.asset.offer_type" class="radios" :options="{
                  إيجار: 'إيجار',
                  بيع: 'بيع',
                  مزاد: 'مزاد',
                }" type="radio" />
                    </div>
                </div>
            </div>

            <div class="group row">
                <div class="col-5">
                    <FormulateInput type="text" v-model="searchValues.price.price_from" @change="validateNumber" name="sample" :placeholder="$t('searchBar.priceFrom')" />
                </div>

                <div class="sparate col-2">_____</div>

                <div class="col-5">
                    <FormulateInput type="text" v-model="searchValues.price.price_to" @change="validateNumber" name="sample" :placeholder="$t('searchBar.priceTo')" />
                </div>

                <div class="group-items colu">
                    <label for="">المناطق</label>
                    <v-select style="height: 37px" v-model="searchValues.asset.region_id" :options="formatOptions(lists.regions)" @input="selectRegion" />
                    <label for="">المدن</label>
                    <v-select v-model="searchValues.asset.city_id" :options="formatOptions(lists.cities)" @input="selectCity" />
                    <label for="">الاحياء</label>
                    <v-select v-model="searchValues.asset.district_id" :options="formatOptions(lists.districts)" />
                </div>
            </div>

            <div class="group row last">
                <div class="col-6" v-for="(property, index) in lists.properties" :key="index">
                    <FormulateInput type="text" @change="lists.assetProprties[index].id = property.id" v-model="lists.assetProprties[index].value" :label="property.name" v-show="property.type == 3" />
                    <FormulateInput @click="lists.assetProprties[index].id = property.id" :label="property.name" v-show="property.type == 4" v-model="lists.assetProprties[index].value" type="checkbox" :value="1" />
                    <FormulateInput @click="lists.assetProprties[index].id = property.id" :label="property.name" :options="formatPropertyOptions(property.options)" v-show="property.type == 2" v-model="lists.assetProprties[index].value" type="select" />
                </div>
            </div>
        </div>

    </div>
    <div class="mobile-drop-down-footer">
        <div class="row">
            <div class="col-6">
                <button class="btn-primary" @click.prevent="advanceSearch">
                    تاكيد
                </button>
            </div>
            <div class="col-6">
                <button class="btn-white" @click="printOffers">PDF</button>
            </div>
        </div>

        <!-- <button class="btn-white" @click="resetSearchData">
          {{ $t("searchBar.reset") }}
        </button> -->

        <!-- <button class="btn-white">
          {{ $t("searchBar.reset") }}
        </button> -->
    </div>
</section>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
    data() {
        return {
            favoritesBox: false,
            firstBox: false,
            secondBox: false,
            thirdBox: false,
            fourthBox: false,
            offer_type: "",
            price_error: "",
            asset_types: [],
            selectedAll: false,
        };
    },
    computed: {
        ...mapGetters("EstateIndex", ["lists", "searchValues", "loading"]),
        ...mapGetters("UserIndex", ["user"]),
    },

    components: {
        "loading-overlay": Loading,
    },

    methods: {
        ...mapActions("EstateIndex", [
            "getAssetTypes",
            "performSearch",
            "setCities",
            "setDistricts",
            "setAssetTypeProperies",
            "locatorUserPosition",
            "searchByUserFav",
            "setAssetsLandPage",
            "resetSearchData",
        ]),
        formatOptions(list) {
            var options = [];
            for (var item of list) {
                options.push({
                    value: item.id,
                    label: item.name,
                });
            }
            return options;
        },
        formatPropertyOptions(list) {
            var options = [];
            for (var item of list) {
                options.push({
                    value: item.id,
                    label: item.option,
                });
            }
            return options;
        },
        async selectRegion(val) {
            if (val != null) this.setCities(val.value);
        },
        async selectCity(val) {
            if (val != null) this.setDistricts(val.value);
        },
        setProperies(obj) {
            this.setAssetTypeProperies(obj.value);
        },
        validateNumber() {
            if (
                parseInt(this.searchValues.price.price_to) <=
                parseInt(this.searchValues.price.price_from)
            ) {
                this.price_error = "يجب ان يكون المبلغ من اصغر من المبلغ الى";
            } else this.price_error = "";
        },
        printOffers() {

            this.searchValues.assetProprties = this.lists.assetProprties;
            this.performSearch().then(() => {
                let elements = [];

                for (let index = 0; index < this.lists.dataSet.length; index++) {
                    elements.push(this.lists.dataSet[index].id);
                }

                window.open(
                    "https://landing.aldabaan.sa/?data=" +
                    elements + "&asset_type=" + this.searchValues.asset.asset_type_id.value + "&offer_type=" + this.searchValues.asset.offer_type + "&name=خالد&phone=500465646",
                    "_blank"
                );

            });

        },

        scrollToTop() {
            const element = document.getElementById("content");
            element.scrollIntoView(true);
        },

        advanceSearch() {
            this.searchValues.assetProprties = this.lists.assetProprties;
            this.performSearch().then(() => {
                this.$modal.hideAll();
                this.scrollToTop();
            });
        },
        closeModal() {
            this.$modal.hideAll();
        },
    },
};
</script>

<style lang="scss" scoped>
.mobile-search-modal {
    direction: rtl;
    height: 100%;
    display: flex;
    flex-direction: column;
    // min-height: 100vh;
    // overflow: auto;
}

.heading {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 15px;
    border-bottom: 1px solid #ccc;

    .heading-close {
        height: 28px;
        width: 28px;
        text-align: center;
        border: 1px solid #EAEAEA;
        background-color: #F9F9F9;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .heading-button {
        button {
            border: none;
            background-color: $primary;
            padding: 4px 8px;
            color: white;
        }
    }
}

.mobile-search-modal {
    .mobile-drop-down {
        padding: 10px;
        padding-bottom: 0px;
        overflow: scroll;
        // height: 100% !important;
        max-height: none !important;

        .mobile-drop-down-body {
            overflow: scroll;

            .group {
                margin-bottom: 20px;

                .group-heading {
                    color: $textDark;
                    font-weight: bold;
                    margin-bottom: 10px;
                }

                .group-items {
                    display: flex;
                    width: 100%;

                    .group-item {
                        width: 100%;
                    }
                }

                .group-items.colu {
                    flex-direction: column;
                }
            }

            .group.last {
                margin-bottom: 0;
            }
        }
    }
}

.mobile-drop-down-footer {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    display: flex;
    flex-direction: column;

    .col-6 {
        text-align: center;
    }

    .btn-primary {
        border: 1px solid $primary;
    }

    button {
        width: 90%;
        margin: 4px auto;
        margin-top: 0;
    }
}
</style>
