<template>
<section class="estate-modal">
    <modal name="not-logged-modal" :adaptive="true" height="auto" :classes="['not-logged-modal']">
        <div>
            <div class="heading">
                <h3>{{ $t("cards.need") }}</h3>
            </div>
            <div class="text">
                {{ $t("cards.please") }}
                <router-link to="/login"> {{ $t("cards.login") }} </router-link>
                {{ $t("cards.first") }}
                <router-link to="/register"> {{ $t("cards.register") }} </router-link>
            </div>
        </div>
    </modal>

    <div class="modal-header">
        <div class="logo" @click="gotoHome">
            <img src="@/assets/logos/logoHome.svg" alt="" />
        </div>

        <div class="like-share" v-if="estate">
            <div class="like" :key="justKey">
                <img src="@/assets/icons/website/like.svg" alt="" v-if="!isLiked" @click="performLike" />
                <img src="@/assets/icons/website/like_fill.svg" alt="" v-if="isLiked" @click="dislikeAsset(asset_id)" />
            </div>
            <!-- <div class="share">
          <img src="@/assets/icons/website/share.svg" alt="" />
        </div> -->
            <div class="share">
                <!-- <img src="@/assets/icons/website/share.svg" alt="" /> -->
                <tooltip placement="bottom" mode="click">
                    <div slot="outlet">
                        <img src="@/assets/icons/website/share.svg" alt="" />
                    </div>
                    <div slot="tooltip" class="tooltip-social-icons">
                        <a :href="
                  'https://www.facebook.com/sharer/sharer.php?u=' +
                  'عرض ' +
                  getAssetTypeName() +
                  ' في ' +
                  getAssetDistrict() +
                  ' ' +
                  'لل' +
                  estate.offer_type +
                  '                                                     ' +
                  baseUrl +
                  '/details/' +
                  estate.id
                " target="_blank">
                            <unicon name="facebook" fill="#fff" height="30" width="30"></unicon>
                        </a>

                        <a :href="
                  'https://twitter.com/intent/tweet?text=' +
                  'عرض ' +
                  getAssetTypeName() +
                  ' في ' +
                  getAssetDistrict() +
                  ' ' +
                  'لل' +
                  estate.offer_type +
                  baseUrl +
                  '/details/' +
                  estate.id
                " target="_blank">
                            <unicon name="twitter" fill="#fff" height="30" width="30"></unicon>
                        </a>

                        <a :href="
                  'https://api.whatsapp.com/send?text=' +
                  'عرض ' +
                  getAssetTypeName() +
                  ' في ' +
                  getAssetDistrict() +
                  ' ' +
                  'لل' +
                  estate.offer_type +
                  '                                                     ' +
                  baseUrl +
                  '/details/' +
                  estate.id
                " target="_blank">
                            <unicon name="whatsapp" fill="#fff" height="30" width="30"></unicon>
                        </a>
                    </div>
                </tooltip>
            </div>
        </div>
    </div>

    <div class="modal-loading-body" v-if="!estate">
        <div class="row">
            <div class="col-6">
                <div class="left-side">
                    <div class="cover-photo-loader-container">
                        <skeleton-loader-vue type="rect" animation="fade" class="cover-photo-loader" />
                    </div>

                    <div class="row">
                        <div class="col-6 photo-loader-container" v-for="index in 10" :key="index">
                            <skeleton-loader-vue type="rect" animation="fade" class="photo-loader" />
                        </div>
                    </div>
                </div>
                <div class="facebook-card"></div>
            </div>
            <div class="col-6">
                <div class="left-side">
                    <div class="cover-photo-loader-container">
                        <skeleton-loader-vue type="rect" animation="fade" class="cover-photo-loader" />
                    </div>

                    <div class="row">
                        <div class="col-6 photo-loader-container" v-for="index in 10" :key="index">
                            <skeleton-loader-vue type="rect" animation="fade" class="photo-loader" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-body" v-if="estate">
        <loading-overlay color="#1B47EA" :active.sync="bookingLoading" :is-full-page="false" :can-cancel="true">
        </loading-overlay>

        <div class="row" v-if="!estate.isActive || !estate.acceptable">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <p class="stopped-asset">نعتذر هذا العرض غير متاح</p>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-lg-5 col-md-6 col-sm-12">
                <div class="text-side">
                    <hooper class="hooper-slider" :settings="hooperSettings" v-if="estate.imageUrl && estate.imageUrl.length > 0">
                        <slide v-for="(img, i) in estate.imageUrl" :key="i" class="hooper-slide">
                            <img :src="img" alt="" @click="index = i" />
                        </slide>
                    </hooper>
                    <vue-gallery-slideshow :images="estate.imageUrl" :index="index" @close="index = null"></vue-gallery-slideshow>
                    <div class="top-side">
                        <div class="row">
                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="price-location" v-if="estate.status == 0">
                                    <div class="price" v-if="estate.soom_price != null">
                                        <span class="modal-span">سوم</span>
                                        {{ formatNumber(estate.soom_price) }}
                                        <span class="modal-span"> رس </span>

                                        <span class="modal-span" v-if="estate.sale_type == 2">
                                            للمتر
                                        </span>
                                    </div>

                                    <div class="price" v-if="estate.offer_price != null">
                                        <span class="modal-span" v-if="estate.offer_type == 'بيع'">بيع</span>
                                        {{ formatNumber(estate.offer_price) }}
                                        <span class="modal-span"> رس </span>
                                        <span class="modal-span" v-if="estate.sale_type == 2">
                                            للمتر
                                        </span>
                                    </div>

                                    <div class="price" v-if="
                        estate.soom_price == null && estate.offer_price == null
                      ">
                                        <span class="modal-span"> لم يسام العقار </span>
                                    </div>
                                    <div class="location" @click="gotoMapLoaction(estate)">
                                        <span class="icon">
                                            <a :href="estate.location.map_url" target="_blank">
                                                <unicon name="map-marker" fill="#1b47ea80" height="20" width="20"></unicon>
                                            </a>
                                        </span>
                                        <span class="text" v-if="estate.district != null">
                                            {{ estate.district.name }}
                                        </span>
                                    </div>
                                </div>

                                <div class="price-location" v-if="estate.status == 1">
                                    <div class="price">
                                        <span class="no-avalable">تم التأجير</span>
                                    </div>
                                    <div class="location" @click="gotoMapLoaction(estate)">
                                        <span class="icon">
                                            <unicon name="map-marker" fill="#1b47ea80" height="20" width="20"></unicon>
                                        </span>
                                        <span class="text" v-if="estate.district != null">
                                            {{ estate.district.name }}
                                        </span>
                                    </div>
                                </div>

                                <div class="price-location" v-if="estate.status == 2">
                                    <div class="price">
                                        <span class="no-avalable">تم البيع</span>
                                    </div>
                                    <div class="location" @click="gotoMapLoaction(estate)">
                                        <span class="icon">
                                            <unicon name="map-marker" fill="#1b47ea80" height="20" width="20"></unicon>
                                        </span>
                                        <span class="text" v-if="estate.district != null">
                                            {{ estate.district.name }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                <div class="contact">
                                    <div class="name font-weight-bold">
                                        <h3>
                                            <!-- <unicon name="user" fill="#555555" height="18" width="18"></unicon> -->
                                            {{ estate.created_by.name }} - مسوق
                                        </h3>
                                    </div>
                                    <div class="contact-icons">
                                        <div>
                                            <div class="wa-widget-call-button">
                                                <a :href="getPhoneNumber(estate.created_by.phone)">
                                                    <img src="@/assets/icons/website/phone_icon.png" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="wa-widget-send-button" @click="sendByWhatsapp(estate.created_by.phone)">
                                                <svg class="wa-messenger-svg-whatsapp wh-svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32">
                                                    <path d="M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fill-rule="evenodd"></path>
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="estate-btns">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6 re" v-if="estate.offer_type == 'إيجار'">
                                <button @click="assetBooking(estate.id)" :disabled="
                      estate.isBooking || estate.status != 0 || isClicked
                    ">
                                    طلب حجز
                                </button>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 re" v-else>
                                <button :disabled="estate.status != 0" @click="openSoomModal">
                                    اضف سومتك
                                </button>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6 re">
                                <button @click="openPreviewModal" class="preview-btn" :disabled="estate.status != 0">
                                    <!-- <unicon
                      name="eye"
                      fill="#fff"
                      height="18"
                      width="18"
                    ></unicon> -->
                                    طلب معاينة
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="estate-data">
                        <!-- <div class="heading">بيانات العقار</div> -->
                        <div class="heading-btn">
                            <div class="text">بيانات العقار</div>
                            <div class="btn" v-if="estate.tour_url != null">
                                <button>جولة</button>
                            </div>
                        </div>
                        <div class="data-items">
                            <div class="row">

                                <!-- <div class="col-lg-4 col-md-6 col-sm-6">
                                    <div class="data-item">
                                        <div class="item-label">رقم الترخيص الاعلاني</div>
                                        <div class="item-value">
                                            {{ estate.ads_number }}
                                        </div>
                                    </div>
                                </div> -->

                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <div class="data-item">
                                        <div class="item-label">رقم الترخيص العقاري</div>
                                        <div class="item-value">1200013113</div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <div class="data-item">
                                        <div class="item-label">رقم العقار</div>
                                        <div class="item-value">
                                            {{ estate.id }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <div class="data-item">
                                        <div class="item-label">نوع العقار</div>
                                        <div class="item-value" v-if="estate.asset_type != null">
                                            {{ estate.asset_type.name }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-6 col-sm-6" bv-if="estate.usege_type != null">
                                    <div class="data-item">
                                        <div class="item-label">نوع الاستخدام</div>
                                        <div class="item-value" v-if="estate.usege_type != null">
                                            {{ estate.usege_type }}
                                        </div>

                                        <div class="item-value" v-else>تجاري</div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <div class="data-item">
                                        <div class="item-label">نوع العرض</div>
                                        <div class="item-value">
                                            {{ estate.offer_type }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6">
                                    <div class="data-item">
                                        <div class="item-label">تصنيف العقار</div>
                                        <div class="item-value">
                                            {{ estate.classfy_asset }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" v-if="estate.pay_way != null">
                                    <div class="data-item">
                                        <div class="item-label">طريقة الدفع</div>
                                        <div class="item-value" v-if="estate.pay_way != 'الكل'">
                                            {{ estate.pay_way }}
                                        </div>

                                        <div class="item-value" v-else>يقبل الجميع</div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" v-if="estate.asset_old != null">
                                    <div class="data-item">
                                        <div class="item-label">عمر العقار</div>
                                        <div class="item-value">{{ estate.asset_old }} سنة</div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" v-if="estate.asset_area != null">
                                    <div class="data-item">
                                        <div class="item-label">مساحة العقار</div>
                                        <div class="item-value">
                                            {{ estate.asset_area }} متر مربع
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" v-if="estate.unit_number != null">
                                    <div class="data-item">
                                        <div class="item-label">رقم الشقة</div>
                                        <div class="item-value">
                                            {{ estate.unit_number }}
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" v-if="estate.income != null">
                                    <div class="data-item">
                                        <div class="item-label">دخل العقار</div>
                                        <div class="item-value">
                                            {{ formatNumber(estate.income) }}
                                            <span class="modal-span"> رس </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" v-if="estate.des != null">
                                    <div class="data-item">
                                        <div class="item-label">
                                            <button type="submit" class="preview-submit" @click="viewAssetDes(estate.des)">
                                                مواصفات العقار
                                            </button>
                                        </div>
                                        <div class="item-value"></div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-6 col-sm-6" v-if="estate.planned_chart != null">
                                    <div class="data-item">
                                        <div class="item-label">
                                            <button type="submit" class="preview-submit" @click="viewAssetPlannedChart(estate.planned_chart)">
                                                مخطط العقار
                                            </button>
                                        </div>
                                        <div class="item-value"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="estate-features">
                        <div class="heading">خواص العقار</div>
                        <div class="data-items">
                            <div class="row">

                                <div v-for="(property, index) in estate.properties" :key="index" class="col-lg-4 col-md-6 col-sm-6">
                                    <div class="data-item">
                                        <div class="item-icon" v-if="property.imageUrl">
                                            <img :src="property.imageUrl[0]" alt="" />
                                        </div>
                                        <div class="item-text">
                                            <div class="number" v-if="property.type == 2">
                                                {{ getOption(property, property.pivot.value) }}
                                            </div>
                                            <div class="number" v-else>
                                                {{ property.pivot.value }}
                                            </div>
                                            <div class="name">
                                                {{ property.name }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="estate-features" v-if="estate.northern_neighbour != null">
                        <div class="heading">الحدود و الاطوال</div>
                        <div class="data-items">
                            <table class="table">
                                <tr>
                                    <th>شمالي</th>
                                    <td class="text-right space-right space-left">
                                        {{ estate.northern_neighbour }}
                                    </td>
                                    <td class="font-weight-bold">بطول</td>
                                    <td class="text-right space-left">
                                        {{ estate.northern_area }} م
                                    </td>
                                    <td class="text-right font-weight-bold" v-if="estate.northern_neighbour == 'شارع'">
                                        عرض الشارع
                                    </td>
                                    <td class="text-right" v-if="estate.northern_neighbour == 'شارع'">
                                        {{ estate.northern_street_width }} م
                                    </td>
                                </tr>
                                <tr>
                                    <th>الجنوبي</th>
                                    <td class="text-right space-right space-left">
                                        {{ estate.southern_neighbour }}
                                    </td>
                                    <td class="text-right font-weight-bold">بطول</td>
                                    <td class="text-right space-left">
                                        {{ estate.southern_area }} م
                                    </td>
                                    <td class="text-right font-weight-bold" v-if="estate.southern_neighbour == 'شارع'">
                                        عرض الشارع
                                    </td>
                                    <td class="text-right" v-if="
                        estate.southern_neighbour == 'شارع' &&
                        estate.southern_street_width != null
                      ">
                                        {{ estate.southern_street_width }} م
                                    </td>
                                </tr>

                                <tr>
                                    <th>الشرقي</th>
                                    <td class="text-right space-right">
                                        {{ estate.east_neighbour }}
                                    </td>
                                    <td class="text-right font-weight-bold" v-if="estate.east_area != null">
                                        بطول
                                    </td>
                                    <td class="text-right">{{ estate.east_area }} م</td>
                                    <td class="text-right font-weight-bold" v-if="estate.east_neighbour == 'شارع'">
                                        عرض الشارع
                                    </td>
                                    <td class="text-right" v-if="estate.east_neighbour == 'شارع'">
                                        {{ estate.east_street_width }} م
                                    </td>
                                </tr>
                                <tr>
                                    <th>الغربي</th>
                                    <td class="text-right">{{ estate.western_neighbour }}</td>
                                    <td class="text-right font-weight-bold">بطول</td>
                                    <td class="text-right">{{ estate.western_area }} م</td>
                                    <td class="text-right font-weight-bold" v-if="estate.western_neighbour == 'شارع'">
                                        عرض الشارع
                                    </td>
                                    <td class="text-right" v-if="estate.western_neighbour == 'شارع'">
                                        {{ estate.western_street_width }} م
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="estate-features" v-if="estate.hasDept">
                        <div class="heading">بيانات القرض البنكي</div>
                        <div class="data-items">
                            <table class="table">
                                <tr>
                                    <th>قيمة القرض</th>
                                    <td class="text-right space-right space-left">
                                        {{ estate.asset_dept.debt }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>عدد الاقساط</th>
                                    <td class="text-right">
                                        {{ estate.asset_dept.installment_number }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>قيمه القسط</th>
                                    <td class="text-right">
                                        {{ estate.asset_dept.installment_value }}
                                    </td>
                                </tr>
                                <tr>
                                    <th>الاقساط المتبقي</th>
                                    <td class="text-right space-right space-left">
                                        {{ estate.asset_dept.installment_remind }}
                                    </td>
                                </tr>

                                <tr>
                                    <th>الاقساط الحاله</th>
                                    <td class="text-right space-right">
                                        {{ estate.asset_dept.installment_active }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>

                    <div class="estate-features" v-if="estate.sooms.length > 0">
                        <div class="heading">منافسة السومات</div>
                        <div class="data-items">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>

                                        <th scope="col">السومة</th>
                                        <th scope="col">التاريخ</th>
                                        <th scope="col">الحالة</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="soom in estate.sooms" :key="soom.id">
                                        <td>{{ soom.id }}</td>
                                        <td>{{ formatNumber(soom.soom_price) }}</td>
                                        <td>{{ dateFormat(soom.created_at) }}</td>
                                        <td v-if="soom.user_id == getUserId">
                                            <button type="submit" class="preview-submit">
                                                تعديل
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="estate-youtube" v-if="estate.youtub_url != null">
                        <div class="heading">فيديو</div>
                        <iframe width="100%" height="315" :src="estate.youtub_url">
                        </iframe>
                    </div>

                    <div class="estate-map">
                        <div class="estate-btns">
                            <div class="row">
                                <div class="col-lg-6 col-md-6 col-sm-6">
                                    <button @click="gotoMapLoaction(estate)" class="preview-btn">
                                        <unicon name="map-marker" fill="#fff" height="18" width="18"></unicon>
                                        اللوكيشن
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="detailsMap"></div>
                    </div>

                    <br /><br />

                    <div class="similar-estates" v-if="
                estate.asset_type.name != 'ارض' &&
                estate.asset_type.isComposit &&
                estate.sup_assets.length > 0
              ">
                        <div class="similar-estates-heading">
                            <div class="text">وحدات ال {{ estate.asset_type.name }}</div>
                        </div>

                        <carousel :paginationEnabled="false" :navigationEnabled="true" class="similar-carousel">
                            <CaSlide v-for="card in estate.sup_assets" :key="card.id">
                                <div class="similar-card" @click="showDetailsModal(card)">
                                    <div class="card-img">
                                        <img v-if="card.img_url" :src="card.img_url" alt="" />
                                    </div>
                                    <div class="card-text">
                                        <div class="text-name">
                                            <div class="name">{{ card.type_name }}</div>
                                            <div class="name">
                                                رقم الدور : {{ card.floor_number }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="features">
                                            <div class="feature" v-if="getUnitRoomsPropertyCount(card) > 0">
                                                <div class="feature-icon">
                                                    <img src="@/assets/icons/website/bedroom.svg" alt="" />
                                                </div>
                                                <div class="feature-label">
                                                    {{ getUnitRoomsPropertyCount(card) }}
                                                </div>
                                            </div>

                                            <div class="feature" v-if="getUnitPathRoomsCount(card) > 0">
                                                <div class="feature-icon">
                                                    <img src="@/assets/icons/website/pathroom.svg" alt="" />
                                                </div>
                                                <div class="feature-label">
                                                    {{ getUnitPathRoomsCount(card) }}
                                                </div>
                                            </div>

                                            <div class="feature" v-if="card.asset_area != null">
                                                <div class="feature-icon">
                                                    <img src="@/assets/icons/website/area.webp" alt="" />
                                                </div>
                                                <div class="feature-label">
                                                    {{ card.asset_area }} متر
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CaSlide>
                        </carousel>
                    </div>

                    <div class="similar-estates" v-if="
                estate.asset_type.name == 'ارض' &&
                estate.sup_assets.length > 0 &&
                estate.offer_type != 'بيع'
              ">
                        <div class="similar-estates-heading">
                            <div class="text">قطع الارض</div>
                        </div>

                        <carousel :paginationEnabled="false" :navigationEnabled="true" class="similar-carousel">
                            <CaSlide v-for="card in estate.sup_assets" :key="card.id">
                                <div class="similar-card" @click="showDetailsModal(card)">
                                    <div class="card-img">
                                        <img v-if="card.img_url" :src="card.img_url" alt="" />
                                        <img v-else src="@/assets/logos/logoSmall.svg" alt="" />
                                    </div>
                                    <div class="card-text">
                                        <div class="text-name">
                                            <div class="name">
                                                رقم القطعة : {{ card.unit_number }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="features">
                                            <div class="feature">
                                                <div class="feature-icon">
                                                    <img src="@/assets/icons/website/area.webp" alt="" />
                                                </div>
                                                <div class="feature-label">
                                                    {{ card.asset_area }} متر مربع
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CaSlide>
                        </carousel>
                    </div>

                    <div class="similar-estates" v-if="similar_offers.length > 0">
                        <div class="similar-estates-heading">
                            <div class="text">عروض مشابهة</div>
                        </div>

                        <carousel :paginationEnabled="false" :navigationEnabled="true" class="similar-carousel">
                            <CaSlide v-for="card in similar_offers" :key="card.id">
                                <div class="similar-card" @click="showDetailsModal(card)">
                                    <div class="card-img">
                                        <img :src="card.img_url" alt="" />
                                    </div>
                                    <div class="card-text">
                                        <div class="text-name">
                                            <div class="name">
                                                {{ card.district.name }}
                                            </div>
                                            <!-- <div class="name">رقم العقار : {{ card.id }}</div> -->
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="features">
                                            <div class="feature" v-if="getRoomsPropertyCount(card) > 0">
                                                <div class="feature-icon">
                                                    <img src="@/assets/icons/website/bedroom.svg" alt="" />
                                                </div>
                                                <div class="feature-label">
                                                    {{ getRoomsPropertyCount(card) }}
                                                </div>
                                            </div>

                                            <div class="feature" v-if="getPathRoomsCount(card) > 0">
                                                <div class="feature-icon">
                                                    <img src="@/assets/icons/website/pathroom.svg" alt="" />
                                                </div>
                                                <div class="feature-label">
                                                    {{ getPathRoomsCount(card) }}
                                                </div>
                                            </div>

                                            <div class="feature" v-if="card.asset_area != null">
                                                <div class="feature-icon">
                                                    <img src="@/assets/icons/website/area.webp" alt="" />
                                                </div>
                                                <div class="feature-label">
                                                    {{ card.asset_area }} م
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CaSlide>
                        </carousel>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-6 col-sm-12" v-if="getScreenWidth() > 767">
                <div class="images-side" v-if="estate.imageUrl.length > 0">
                    <div class="row">
                        <div class="col-12">
                            <a href="#">
                                <div class="head-image-item" v-if="estate.imageUrl">
                                    <img :src="estate.imageUrl[0]" alt="" @click="index = 0" />
                                </div>
                            </a>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-12" v-for="(image, i) in estate.imageUrl" :key="i">
                            <a href="#">
                                <div class="image-item">
                                    <img class="image" :src="image" @click="index = i" />
                                </div>
                            </a>
                        </div>
                    </div>

                    <vue-gallery-slideshow :images="estate.imageUrl" :index="index" @close="index = null"></vue-gallery-slideshow>
                </div>

            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    Carousel
} from "vue-carousel";
import CaSlide from "vue-carousel/src/Slide.vue";
import PreviewModal from "@/components/PreviewModal";
import SoomModal from "@/components/SoomModal";
import NotLoggedModal from "./NotLoggedModal";
import AssetDes from "./AssetDes.vue";
import VueSkeletonLoader from "skeleton-loader-vue";
import VueGallerySlideshow from "vue-gallery-slideshow";
import PlannedChart from "./PlannedChart.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
    Hooper,
    Slide
} from "hooper";
import "hooper/dist/hooper.css";
import {
    plainAxiosInstance
} from "../axios/index";
var numeral = require("numeral");
import {
    mapGetters,
    mapActions
} from "vuex";
export default {
    metaInfo() {
        return {
            title: this.estateLoading ? "Loading...." : `الضبعان العقارية | ${this.estate.asset_type.name} في ${this.estate.district.name}`,
            meta: [

                {
                    property: "og:image",
                    content: this.estateLoading ? "https://aldabaan.sa/logo-250x250.jpeg" : this.estate.imageUrl[0],
                },
                {
                    property: "og:description",
                    content: this.pageDescription
                },
            ],
        };
    },
    components: {
        VueGallerySlideshow,
        "skeleton-loader-vue": VueSkeletonLoader,
        "loading-overlay": Loading,
        Hooper,
        Slide,
        Carousel,
        CaSlide,
    },
    data() {
        return {
            pageTitle: "asasasas",
            pageDescription: "bbbbb",
            pageImage: "asas",
            isClicked: false,
            img_index: 1,
            map: undefined,
            center: {
                lat: 24.8184709,
                lng: 46.8028893,
                z: 20,
            },
            items: [0, 0, 0],
            hooperSettings: {
                itemsToShow: 1,
                centerMode: false,
                autoPlay: true,
            },
            discription: "",
            index: null,
            asset_id: null,
            estate: undefined,
            isLiked: false,
            justKey: 1,
            auth_user: JSON.parse(localStorage.getItem("deb-us")),
            simCards: [0, 1, 2, 3, 4],
            similar_offers: [],
            estateLoading: true,
        };
    },
    // watch: {
    //   "$route.params.id": {
    //     immediate: true,
    //     handler() {
    //       this.setAssetData(this.$route.params.id);
    //     },
    //   },
    // },
    computed: {
        ...mapGetters("EstateIndex", ["info", "bookingLoading"]),
        ...mapGetters("UserIndex", ["user_id", "user", "baseUrl"]),

        getUserId() {
            if (this.auth_user != null) return this.auth_user.id;
            else return 0;
        },
    },

    methods: {
        ...mapActions("EstateIndex", ["addBooking"]),
        ...mapActions("UserIndex", ["dislikeAsset", "likeAsset"]),
        showLoggedModal() {
            this.$modal.show(
                NotLoggedModal, {}, {
                    adaptive: true,
                    height: "auto",
                    scrollable: false,
                }
            );
        },

        getAssetTypeName() {
            if (this.estate.asset_type != null) return this.estate.asset_type.name;
            else return "";
        },

        getAssetDistrict() {
            if (this.estate.district != null) return this.estate.district.name;
            else return "";
        },

        getOption(item, value) {
            for (let index = 0; index < item.options.length; index++)
                if (item.options[index].id == value) return item.options[index].option;
        },

        sendByWhatsapp(phone_number) {
            var phone = "966" + phone_number;
            var msg =
                "السلام عليكم مسوق شركة الضبعان العقارية بخصوص الاعلان :  " +
                "https://aldabaan.sa/details/" +
                this.estate.id;
            window.open(`https://api.whatsapp.com/send?phone=${phone}&text=${msg}`);
        },
        hideLoggedModal() {
            this.$modal.hide("not-logged-modal");
        },
        performLike() {
            if (this.user) {
                this.likeEstate();
            } else {
                this.showLoggedModal();
            }
        },

        gotoHome() {
            this.$router.push({
                name: "home",
            });
        },
        openPreviewModal() {
            if (this.user) {
                this.$modal.show(
                    PreviewModal, {}, {
                        name: "preview-modal",
                        adaptive: true,
                        height: "auto",
                        scrollable: false,
                    }
                );
            } else {
                this.showLoggedModal();
            }
        },

        dateFormat($item) {
            var date = new Date($item);
            return this.format(date);
        },

        format(date) {
            var month = date.toLocaleString("ar-EG", {
                month: "short",
            });
            return month + " " + date.getDate() + " " + date.getFullYear();
        },

        openSoomModal() {
            if (this.user) {
                this.$modal.show(
                    SoomModal, {}, {
                        adaptive: true,
                        height: "auto",
                        scrollable: false,
                    }
                );
            } else {
                this.showLoggedModal();
            }
        },
        assetBooking(asset_id) {
            if (this.user) {
                let pram = {
                    user_id: this.user.id,
                    asset_id: asset_id,
                    u_i: 1,
                };
                this.addBooking(pram).then(() => {
                    this.isClicked = true;
                });
            } else {
                this.showLoggedModal();
            }
        },
        getEstateDetails() {
            this.estateLoading = true
            if (this.auth_user != null) var user_id = this.auth_user.id;
            else user_id = 0;
            plainAxiosInstance
                .get("asset/" + this.$route.params.id + "?user_id=" + user_id)
                .then((response) => {
                    this.estateLoading = false
                    this.estate = response.data[0];
                    this.isLiked = this.estate.isLiked;
                    this.asset_id = this.estate.id;
                    this.getSimilarOffers();
                })
                .catch((error) => {
                    this.estateLoading = false
                    // handle error
                    console.log(error);
                })
                .then(() => {
                    this.initMap();
                });
        },

        getSimilarOffers() {
            let pram = {
                fromLangPage: true,
                notId: this.estate.id,
                asset: {
                    asset_type_id: this.estate.asset_type_id,
                    usege_type: this.estate.usege_type,
                    offer_type: this.estate.offer_type,
                },
            };

            plainAxiosInstance.post("asset/search", pram).then((response) => {
                this.similar_offers = response.data.data;
            });
        },

        likeEstate() {
            this.likeAsset(this.estate.id).then(() => {
                this.justKey += 1;
                this.isLiked = true;
            });
        },
        initMap() {
            this.map = new window.google.maps.Map(
                document.getElementById("detailsMap"), {
                    center: this.getPositions(this.estate),
                    zoom: 15,
                    gestureHandling: "cooperative",
                }
            );
            var icon = {
                url: this.getMarkerIcon(this.estate.offer_type), // url
                scaledSize: new window.google.maps.Size(30, 30), // scaled size
                origin: new window.google.maps.Point(0, 0), // origin
                anchor: new window.google.maps.Point(0, 0), // anchor
            };
            var marker = new window.google.maps.Marker({
                position: this.getPositions(this.estate),
                map: this.map,
                icon: icon,
                // title: this.estate.asset_type.name,
            });
            marker.setIcon(icon);
            marker.setMap(this.map);
        },
        getMarkerColor(item) {
            if (item.offer_type == "بيع") return "red";
            else if (item.offer_type == "إيجار") return "#FFFF00";
            else return "#4A148C";
        },

        getPhoneNumber(phone) {
            return "tel:0" + phone;
        },

        getMarkerIcon(offer_type) {
            if (offer_type == "بيع")
                return "https://aldabaan.sa/markers/Untitled-112.png";
            else if (offer_type == "إيجار")
                return "https://aldabaan.sa/markers/Untitled-123.png";
            else if (offer_type == "hover")
                return "https://aldabaan.sa/markers/Untitled-111.png";
            else return "https://aldabaan.sa/markers/Untitled-1578.png";
        },
        getPositions(r) {
            if (r.location != null)
                return {
                    lat: parseFloat(r.location.lat),
                    lng: parseFloat(r.location.lng),
                };
            else this.center;
        },
        showDetailsModal(item) {
            this.$router.push({
                path: "/details/" + item.id,
            });
            this.estate = undefined;
            this.estateLoading = true
            this.getEstateDetails();
        },

        gotoMapLoaction(item) {
            if (item.location != null) window.open(item.location.map_url, "_blank");
        },

        formatNumber(value) {
            return numeral(value).format("0,0");
        },

        getRoomsPropertyCount(item) {
            for (let index = 0; index < item.properties.length; index++) {
                if (item.properties[index].name == "عدد الغرف")
                    return item.properties[index].pivot.value;
            }
        },

        getPathRoomsCount(item) {
            for (let index = 0; index < item.properties.length; index++) {
                if (item.properties[index].name == "عدد الحمامات")
                    return item.properties[index].pivot.value;
            }
        },

        getUnitRoomsPropertyCount(item) {
            for (let index = 0; index < item.properties.length; index++) {
                if (item.properties[index].name == "عدد الغرف")
                    return item.properties[index].value;
            }
        },

        getUnitPathRoomsCount(item) {
            for (let index = 0; index < item.properties.length; index++) {
                if (item.properties[index].name == "عدد الحمامات")
                    return item.properties[index].value;
            }
        },

        viewAssetDes(des) {
            this.info.discription = des;
            this.$modal.show(
                AssetDes, {}, {
                    adaptive: true,
                    height: "80%",
                    scrollable: true,
                }
            );
        },
        getScreenWidth() {
            console.log("Screen width");
            console.log(window.screen.width);
            return window.screen.width;
        },

        viewAssetPlannedChart(chart_url) {
            this.info.planned_chart_url = chart_url;
            this.$modal.show(
                PlannedChart, {}, {
                    adaptive: true,
                    height: "auto",
                    scrollable: false,
                }
            );
        },
    },
    mounted() {
        this.getScreenWidth();
        // this.initMap();
    },
    created() {
        this.getEstateDetails();
    },
};
</script>

<style lang="scss" scoped>
.estate-modal {
    direction: rtl;
    text-align: start;
}

.stopped-asset {
    margin-top: 0;
    text-align: center;
    margin-bottom: 1rem;
    color: red;
    font-size: 30px;
}

.wa-widget-send-button,
.wa-widget-call-button {
    margin: 0 0 50px 0 !important;
    padding-left: 0px;
    padding-right: 0px;
    text-align: center !important;
    height: 50px;
    min-width: 50px;
    border-radius: 25px;
    background-color: #1b47ea;
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        margin-top: 0 !important;
    }
}

// .wa-widget-call-button {
//   margin: 0 0 50px 0 !important;
//   padding-left: 0px;
//   padding-right: 0px;
//   text-align: center !important;
//   height: 50px;
// width: 50px;
//   min-width: 30px;
//   border-radius: 50%;
//   background-color: #1b47ea;
//   box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.4);
// }

.wa-messenger-svg-whatsapp {
    fill: white;
    width: 35px;
    height: 40px;
    stroke: none;
}

.modal-span {
    font-size: 15px;
}

.modal-header {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #ccc;

    .like-share {
        display: flex;

        .like,
        .share {
            margin-left: 10px;
            width: 40px;
            cursor: pointer;

            img {
                width: 100%;
            }
        }
    }

    .logo {
        img {
            width: 50%;
        }
    }
}

.no-avalable {
    color: #d32f2f;
}

.modal-body {
    padding: 10px;
    padding-right: 3px;

    .text-side {
        height: 100vh;
        padding-bottom: 15vh;
        overflow: auto;
        direction: ltr;
        padding-right: 10px;
        padding-left: 10px;
    }

    .top-side {
        padding: 10px;
        // display: flex;
        // justify-content: space-between;
        direction: rtl;
        margin-bottom: 10px;

        .price-location {
            .price {
                font-size: 25px;
                font-weight: bold;
                color: $textDark;
            }

            .location {
                font-size: 20px;
            }
        }

        .contact {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .name {
                margin-bottom: 10px;
                color: $text;
                text-align: end;
            }

            .contact-icons {
                display: flex;

                div {
                    margin-left: 10px;
                    cursor: pointer;

                    img {
                        width: 40%;
                        margin-top: 10px;
                    }
                }
            }
        }
    }

    .estate-btns {
        margin-bottom: 30px;

        .re {
            padding: 2px;
        }

        button {
            width: 100%;
            border: 1px solid $primary;

            background-color: transparent;
            padding: 4px;
            color: $primary;
            font-weight: bold;
        }

        .preview-btn {
            color: white;
            background-color: $primary;
        }
    }

    .estate-data {
        direction: rtl;
        margin-top: 20px;

        .heading {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .heading-btn {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;

            .text {
                font-size: 18px;
                font-weight: bold;
            }

            button {
                border: none;
                background: $primary;
                color: white;
                padding: 3px 20px;
                border-radius: 4px;
                font-size: 17px;
                font-weight: bold;
                width: 235px;
            }
        }

        .data-items {
            // display: flex;
            // align-items: center;
            // justify-content: space-evenly;
            border: 1px solid #ccc;
            padding: 10px 5px;
            border-radius: 6px;

            .data-item {
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-bottom: 20px;

                .item-label {
                    font-size: 14px;
                    font-weight: bold;
                    margin-bottom: 5px;
                    color: $text;
                    margin-left: 5px;
                    font-weight: bold;
                    color: black;
                }

                .item-value {
                    font-size: 14px;
                }
            }
        }
    }

    .estate-features {
        margin-top: 30px;
        direction: rtl;

        .heading {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .data-items {
            border: 1px solid #ccc;
            padding: 10px;
            border-radius: 6px;

            .data-item {
                display: flex;
                align-items: flex-end;

                .item-icon {
                    width: 40px;

                    img {
                        width: 100%;
                    }
                }

                .item-text {
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 5px;

                    .number {
                        font-size: 30px;
                        font-weight: bold;
                        color: $primary;
                        padding: 6px;
                    }

                    .name {
                        font-size: 14px;
                        font-weight: bold;
                        color: $text;
                    }
                }
            }
        }
    }

    .estate-map {
        height: 400px;
        margin-top: 40px;

        #detailsMap {
            height: 100%;
            width: 100%;
            border-right: 1px solid #ccc;

            .map-view {
                height: 100%;
            }
        }
    }
}

.images-side {
    direction: ltr;
    height: 100vh;
    padding-bottom: 15vh;
    overflow: auto;

    .head-image-item {
        height: 350px;
        margin: 2px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }

    .image-item {
        margin: 2px;
        height: 280px;

        img {
            width: 100%;
            height: 100%;
            border-radius: 5px;
        }
    }
}

.modal-loading-body {

    .left-side,
    .right-side {
        padding: 10px;
    }

    .cover-photo-loader-container {
        padding: 5px;
    }

    .cover-photo-loader {
        height: 200px !important;
        width: 100% !important;
    }

    .photo-loader {
        height: 100px !important;
        width: 100% !important;
    }

    .photo-loader-container {
        padding: 5px;
    }
}

.hooper-slider {
    display: none;
    direction: ltr;
}

@media (max-width: 768px) {
    .estate-features {
        .table {
            th {
                display: block;
            }

            td {
                margin: 0 !important;
                padding: 0 !important;
            }
        }
    }

    .modal-body {
        .estate-data {
            .data-items {
                padding: 10px;

                .data-item {
                    flex-direction: row;
                }
            }
        }

        .estate-features {
            .data-items {
                .data-item {
                    flex-direction: row;
                    justify-content: flex-start;
                }
            }
        }
    }

    .hooper-slider {
        display: block;

        .hooper-slide {
            img {
                background-size: contain;
                height: 100%;
                width: 100%;
            }
        }
    }
}

/* width */
::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #1b47ea80;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $primary;
}

.similar-estates {
    margin-top: 30px;

    .similar-estates-heading {
        font-weight: bold;
        font-size: 18px;
        text-align: start;
        direction: rtl;
    }

    .similar-card {
        z-index: 1;
        margin-left: 10px;
        margin-bottom: 20px;
        border: 1px solid #ccc;
        border-radius: 6px;
        overflow: hidden;
        direction: rtl;

        .card-img {
            cursor: pointer;
            position: relative;

            .top-right,
            .top-left {
                position: absolute;
                top: 0;
                background: #00000080;
                color: #fff;
                padding: 4px 12px;
            }

            .top-right {
                right: 0;

                .dot {
                    display: inline-block;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    margin-right: 5px;
                    background: $danger;
                }

                .dot.sale {
                    background: red;
                }

                .dot.offer {
                    background: #673ab7;
                }

                .dot.yellow {
                    background: #f0ad4e;
                }
            }

            .top-left {
                left: 0;
            }

            width: 100%;
            height: 220px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .card-text {
            padding: 10px;

            .text-name {
                display: flex;
                justify-content: space-between;
                cursor: pointer;

                .name {
                    font-weight: bold;
                }

                .btn {
                    button {
                        font-size: 14px;
                        border: 1px solid $text;
                        background-color: transparent;
                        color: $text;
                        border-radius: 3px;
                        padding-left: 0;
                    }
                }
            }

            .text-des {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                color: $text;
                font-size: 14px;
            }
        }

        .card-footer {
            background-color: #f7f7f7;
            padding: 10px;
            display: flex;
            justify-content: space-between;

            .features {
                display: flex;
                align-items: center;

                .feature {
                    display: flex;
                    align-items: center;
                    margin-left: 10px;

                    .feature-icon {
                        width: 25px;
                        height: 25px;

                        img {
                            height: 100%;
                            width: 100%;
                        }
                    }
                }
            }

            .like-share {
                display: flex;
                direction: rtl;

                .like,
                .share {
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    direction: rtl;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .share {
                    margin-left: 10px;
                    position: relative;

                    .tooltip-social-icons {
                        // width: 100px;
                        // height: 50px;

                        img {
                            margin-right: 10px;
                            width: 30px;
                            height: 30px;
                        }
                    }
                }
            }
        }
    }
}

.similar-carousel {
    width: 93%;
    margin: 0 auto;
}

table {
    .space-right {
        padding-right: 10px;
    }

    .space-left {
        padding-left: 10px;
    }
}

.col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
}

.estate-youtube {
    .heading {
        text-align: right;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 10px;
    }

    margin-top: 30px;
}

.page-heading {
    text-align: start;
}

table {
    border-collapse: collapse;
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
}

.table th,
.table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6;
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

.table-bordered {
    border: 1px solid #dee2e6;
}

.table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6;
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
    border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary>th,
.table-primary>td {
    background-color: #b8daff;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
    border-color: #7abaff;
}

.table-hover .table-primary:hover {
    background-color: #9fcdff;
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
    background-color: #9fcdff;
}

.table-secondary,
.table-secondary>th,
.table-secondary>td {
    background-color: #d6d8db;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
    border-color: #b3b7bb;
}

.table-hover .table-secondary:hover {
    background-color: #c8cbcf;
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
    background-color: #c8cbcf;
}

.table-success,
.table-success>th,
.table-success>td {
    background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
    border-color: #8fd19e;
}

.table-hover .table-success:hover {
    background-color: #b1dfbb;
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
    background-color: #b1dfbb;
}

.table-info,
.table-info>th,
.table-info>td {
    background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
    border-color: #86cfda;
}

.table-hover .table-info:hover {
    background-color: #abdde5;
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
    background-color: #abdde5;
}

.table-warning,
.table-warning>th,
.table-warning>td {
    background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
    border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
    background-color: #ffe8a1;
}

.table-danger,
.table-danger>th,
.table-danger>td {
    background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
    border-color: #ed969e;
}

.table-hover .table-danger:hover {
    background-color: #f1b0b7;
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
    background-color: #f1b0b7;
}

.table-light,
.table-light>th,
.table-light>td {
    background-color: #fdfdfe;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody+tbody {
    border-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ececf6;
}

.table-hover .table-light:hover>td,
.table-hover .table-light:hover>th {
    background-color: #ececf6;
}

.table-dark,
.table-dark>th,
.table-dark>td {
    background-color: #c6c8ca;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody+tbody {
    border-color: #95999c;
}

.table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.table-hover .table-dark:hover>td,
.table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.table-active,
.table-active>th,
.table-active>td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #fff;
    background-color: #343a40;
    border-color: #454d55;
}

.table .thead-light th {
    color: #495057;
    background-color: #e9ecef;
    border-color: #dee2e6;
}

.table-dark {
    color: #fff;
    background-color: #343a40;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #454d55;
}

.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-sm>.table-bordered {
        border: 0;
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-md>.table-bordered {
        border: 0;
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-lg>.table-bordered {
        border: 0;
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .table-responsive-xl>.table-bordered {
        border: 0;
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.table-responsive>.table-bordered {
    border: 0;
}

.d-table {
    display: table !important;
}

.d-table-row {
    display: table-row !important;
}

.d-table-cell {
    display: table-cell !important;
}

.preview-submit {
    background-color: $primary;
    color: white;
    padding: 4px 10px;
    border: none;
}
</style>
