<template>
<section class="mobile-search-bar">
    <div class="container">
        <div class="search-bar">
            <div class="asset_types">
                <button @click="showMobileOffersModal()">عروض عقارية</button>

            </div>
            <div class="advanced-search">
                <button @click="showMobileSearchModal()">بحث متقدم</button>
            </div>

            <div class="asset_types">
                <button @click="locatorUserPosition" class="preview-btn">
                    <unicon name="map-marker" fill="#1b47ea" height="18" width="18"></unicon>
                    موقعي
                </button>
            </div>
        </div>
    </div>
</section>
</template>

<script>
import {
    mapGetters,
    mapActions
} from "vuex";
import MobileSearchModal from "./MobileSearchModal";
import MobileOffersModal from "./MobileOffersModal"
export default {
    data() {
        return {
            favoritesBox: false,
            firstBox: false,
            secondBox: false,
            thirdBox: false,
            fourthBox: false,
            offer_type: "",
            price_error: "",
            asset_types: [],
            selectedAll: false,
        };
    },
    computed: {
        ...mapGetters("EstateIndex", ["lists", "searchValues"]),
        ...mapGetters("UserIndex", ["user"]),
    },
    methods: {
        ...mapActions("EstateIndex", [
            "getAssetTypes",
            "performSearch",
            "setCities",
            "setDistricts",
            "setAssetTypeProperies",
            "locatorUserPosition",
            "searchByUserFav",
            "setAssetsLandPage",
            "resetSearchData",
        ]),
        closeModal() {
            this.$modal.hideAll();
        },
        closeAll() {
            this.favoritesBox = false;
            this.firstBox = false;
            this.secondBox = false;
            this.thirdBox = false;
            this.fourthBox = false;
        },
        resetSelectedTypes() {
            this.searchValues.asset_type = [];
        },
        validateNumber() {
            if (
                parseInt(this.searchValues.price.price_to) <=
                parseInt(this.searchValues.price.price_from)
            ) {
                this.price_error = "يجب ان يكون المبلغ من اصغر من المبلغ الى";
            } else this.price_error = "";
        },
        selectedAllEvent() {
            if (!this.selectedAll) {
                for (var i in this.lists.asset_types) {
                    this.searchValues.asset_type.push(this.lists.asset_types[i].id);
                }
            } else this.searchValues.asset_type = [];
        },
        showFavoriteBox() {
            this.resetSearchData();
            this.closeAll();
            this.favoritesBox = true;
        },
        showFirstBox() {
            this.resetSearchData();
            this.firstBox = !this.firstBox;
            this.secondBox = false;
            this.thirdBox = false;
        },
        showSecondBox() {
            this.resetSearchData();
            this.firstBox = false;
            this.secondBox = !this.secondBox;
            this.thirdBox = false;
        },
        showThirdBox() {
            this.resetSearchData();
            this.firstBox = false;
            this.secondBox = false;
            this.thirdBox = !this.thirdBox;
        },
        showFourthBox() {
            this.resetSearchData();
            this.closeAll();
            this.fourthBox = true;
        },

        onClickOutside() {
            this.closeAll();
        },
        preformQuickSearch() {
            if (this.offer_type == 1) this.searchValues.asset.offer_type = "إيجار";
            else if (this.offer_type == 2) this.searchValues.asset.offer_type = "بيع";
            else this.searchValues.asset.offer_type = "مزاد";
            this.performSearch().then(() => {
                this.firstBox = false;
            });
        },

        offerTypesSearch() {
            this.performSearch().then(() => {
                this.closeAll();
                this.resetSelectedTypes();
            });
        },

        showMobileSearchModal() {
            this.resetSearchData();
            this.$modal.show(
                MobileSearchModal, {}, {
                    adaptive: true,
                    width: "100%",
                    height: "100%",
                    scrollable: true,
                }, {}
            );
        },
        showMobileOffersModal() {
            this.resetSearchData();
            this.$modal.show(
                MobileOffersModal, {}, {
                    adaptive: true,
                    width: "100%",
                    height: "100%",
                    scrollable: true,
                }, {}
            );
        }
    },
};
</script>

<style lang="scss" scoped>
.search-bar {
    display: flex;
    padding-left: 10px;
    padding-right: 10px;
    //border-top: 1px solid #ccc;

    button {
        background-color: white;
        padding: 4px 18px;
        border: 1px solid rgb(209, 209, 213);
        color: $primary;
        border-radius: 3px;
        margin-left: 10px;
        font-size: 14px;

        &:focus {
            outline-color: $primary;
        }
    }
}

.price-field,
.asset_types,
.for-rent {
    position: relative;

    .drop-down {
        width: 100%;
        position: absolute;
        top: 40px;
        right: 10;
        border: 1px solid #ccc;
        background: white;
        z-index: 999;
        border-radius: 4px;

        .drop-down-body {
            padding: 20px 10px 0px 10px;

            .drop-down-option {
                padding: 8px 10px;

                .icon {
                    margin-left: 4px;
                }

                cursor: pointer;

                &:hover {
                    background: #00000010;
                }
            }
        }

        .drop-down-footer {
            padding: 15px;
        }
    }
}

.price-field {
    .drop-down {
        right: -50px;
    }
}



.asset_types {
    .drop-down {
        left: -50px;
    }
}
</style>
