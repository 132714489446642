<template>
  <div class="cards-side">
    <div class="top-bar">
      <div class="filter">
        <span>
          {{ $t("cards.sortBy") }}
        </span>

        <div class="select-input">
          <select
            @change="orderBy"
            v-model="order_asset"
            class="form-control sort-by"
          >
            <option value="اقل الأسعار">
              {{ $t("cards.priceLow") }}
            </option>
            <option value="اعلى الأسعار">
              {{ $t("cards.priceHigh") }}
            </option>
            <option value="المساحة">
              {{ $t("cards.squareFeet") }}
            </option>

            <option value="الاحدث إضافة">
              {{ $t("cards.newAsset") }}
            </option>
          </select>
        </div>
      </div>

      <div>
        <span>{{ $t("cards.totalResults") }}</span> : {{ paginationData.total }}
      </div>
    </div>
    <div class="cards" id="content">
      <div
        class="empty-data"
        v-if="lists.dataSet.length == 0 && !loading && info.noDataFound"
      >
        <img src="@/assets/icons/website/search_error.svg" alt="" />
        <h3>
          {{ $t("cards.noResults") }}
        </h3>
        <p>
          {{ $t("cards.noResultsDetails") }}
        </p>
      </div>

      <div
        class="empty-data"
        v-if="
          (lists.dataSet.length == 0 && loading) ||
          (lists.dataSet.length == 0 && !loading && !info.noDataFound)
        "
      >
        <loading-overlay
          color="#1B47EA"
          :active.sync="checkData"
          :is-full-page="false"
          :can-cancel="true"
        >
        </loading-overlay>
      </div>

      <div class="row">
        <div
          class="col-xl-6 col-lg-6 col-md-12 col-sm-12"
          v-for="(item, index) in lists.dataSet"
          :key="index"
        >
          <div
            class="card"
            :class="loading ? 'card-loading' : ''"
            @mouseover="handleHover(item.id)"
            @mouseleave="handleLeave(item.id)"
          >
            <div class="card-img" @click="showDetailsModal(item)">
              <div class="top-right">
                <span :class="'dot ' + getColor(item)"></span>
                <span class="text"> {{ item.offer_type }} </span>
              </div>
              <div v-if="item.spendedDays.noShowed == null">
                <div class="top-left" v-if="item.spendedDays.type == 1">
                  <span class="text" v-if="item.spendedDays.value <= 2"
                    >{{ item.spendedDays.value }} {{ $t("cards.month") }}
                  </span>
                  <span class="text" v-else
                    >{{ item.spendedDays.value }} {{ $t("cards.months") }}
                  </span>
                </div>

                <div class="top-left" v-if="item.spendedDays.type == 2">
                  <span class="text" v-if="item.spendedDays.value <= 2"
                    >{{ item.spendedDays.value }} {{ $t("cards.day") }}
                  </span>
                  <span class="text" v-else
                    >{{ item.spendedDays.value }} {{ $t("cards.days") }}
                  </span>
                </div>

                <div class="top-left" v-if="item.spendedDays.type == 3">
                  <span class="text"
                    >{{ item.spendedDays.value }} {{ $t("cards.hower") }}
                  </span>
                </div>

                <div class="top-left" v-if="item.spendedDays.type == 4">
                  <span class="text"
                    >{{ item.spendedDays.value }} {{ $t("cards.minute") }}
                  </span>
                </div>

                <div class="top-left" v-if="item.spendedDays.type == 5">
                  <span class="text"
                    >{{ item.spendedDays.value }} {{ $t("cards.second") }}
                  </span>
                </div>
              </div>

              <!-- <lazy-component> -->
              <img v-lazy="{ src: item.img_url }" />
              
              <!-- </lazy-component> -->

            </div>
            <div class="card-text">
              <div class="text-name" @click="showDetailsModal(item)">
                <div class="name">
                  <span v-if="item.asset_type != null">
                    {{ item.asset_type.name }}
                  </span>
                  <span v-if="item.district != null"
                    >في {{ item.district.name }}</span
                  >
                </div>
                <div class="btn">
                  <button>
                    التفاصيل
                    <unicon
                      name="angle-left-b"
                      fill="#1b47ea80"
                      height="18"
                      width="18"
                    ></unicon>
                  </button>
                </div>
              </div>
              <div class="text-des">
                <div class="location">
                  <span class="icon" v-if="item.location != null">
                    <a :href="item.location.map_url" target="_blank">
                      <unicon
                        name="map-marker"
                        fill="#1b47ea80"
                        height="18"
                        width="18"
                      ></unicon>
                    </a>
                  </span>
                  <span class="text" v-if="item.district != null">
                    {{ item.district.name }} - {{ item.district.city.name }}
                  </span>
                </div>

                <div
                  class="price2"
                  v-if="item.offer_price == null && item.soom_price == null"
                >
                  <h3 class="font-weight-bold">لم يسام العقار</h3>
                </div>

                <div class="price1 font-weight-bold" v-else>
                  <h3 class="font-weight-bold" v-if="item.offer_price != null">
                    {{ formatNumber(item.offer_price) }}
                    <span v-if="item.sale_type == 2">للمتر</span>
                  </h3>

                  <h3 class="font-weight-bold" v-else>
                    سوم : {{ formatNumber(item.soom_price) }}
                  </h3>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="features">
                <div class="feature" v-if="getRoomsPropertyCount(item) > 0">
                  <div class="feature-icon">
                    <img src="@/assets/icons/website/bedroom.svg" alt="" />
                  </div>
                  <div class="feature-label">
                    {{ getRoomsPropertyCount(item) }}
                  </div>
                </div>

                <div class="feature" v-if="getPathRoomsCount(item) > 0">
                  <div class="feature-icon">
                    <img src="@/assets/icons/website/pathroom.svg" alt="" />
                  </div>
                  <div class="feature-label">{{ getPathRoomsCount(item) }}</div>
                </div>

                <div class="feature" v-if="item.asset_area != null">
                  <div class="feature-icon">
                    <img src="@/assets/icons/website/area.webp" alt="" />
                  </div>
                  <div class="feature-label">{{ item.asset_area }} م</div>
                </div>
              </div>
              <div class="like-share">
                <div class="share">
                  <!-- <img src="@/assets/icons/website/share.svg" alt="" /> -->
                  <tooltip placement="top-left" mode="click">
                    <div slot="outlet">
                      <img src="@/assets/icons/website/share.svg" alt="" />
                    </div>
                    <div slot="tooltip" class="tooltip-social-icons">
                      <a
                        :href="
                          'https://www.facebook.com/sharer/sharer.php?u=' +
                          'عرض ' +
                          item.asset_type.name +
                          ' في ' +
                          item.district.name +
                          ' ' +
                          'لل' +
                          item.offer_type +
                          '                                                     ' +
                          baseUrl +
                          '/details/' +
                          item.id
                        "
                        target="_blank"
                      >
                        <unicon
                          name="facebook"
                          fill="#fff"
                          height="30"
                          width="30"
                        ></unicon>
                      </a>

                      <a
                        :href="
                          'https://twitter.com/intent/tweet?text=' +
                          'عرض ' +
                          item.asset_type.name +
                          ' في ' +
                          item.district.name +
                          ' ' +
                          'لل' +
                          item.offer_type +
                          '                                                     ' +
                          baseUrl +
                          '/details/' +
                          item.id
                        "
                        target="_blank"
                      >
                        <unicon
                          name="twitter"
                          fill="#fff"
                          height="30"
                          width="30"
                        ></unicon>
                      </a>

                      <a
                        :href="
                          'https://api.whatsapp.com/send?text=' +
                          'عرض ' +
                          item.asset_type.name +
                          ' في ' +
                          item.district.name +
                          ' ' +
                          'لل' +
                          item.offer_type +
                          '                                                     ' +
                          baseUrl +
                          '/details/' +
                          item.id
                        "
                        target="_blank"
                      >
                        <unicon
                          name="whatsapp"
                          fill="#fff"
                          height="30"
                          width="30"
                        ></unicon>
                      </a>
                    </div>
                  </tooltip>
                </div>

                <div
                  class="like"
                  @click="dislikeAsset(item.id)"
                  v-if="item.isLiked"
                  :key="justKey"
                >
                  <img src="@/assets/icons/website/like_fill.svg" alt="" />
                </div>
                <div
                  class="like"
                  @click="performLike(item)"
                  v-if="!item.isLiked"
                  :key="justKey"
                >
                  <img src="@/assets/icons/website/like.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="load-more" v-if="lists.dataSet.length > 0">
        <div class="load-more-btns">
          <button
            class="prev-next prev"
            @click="setAssetsData(paginationData.currentPage - 1)"
            :disabled="paginationData.currentPage === 1"
          >
            <unicon
              name="angle-right"
              fill="#ffffff"
              height="18"
              width="18"
            ></unicon>
            {{ $t("cards.previous") }}
          </button>
          <div class="btns-body">
            <button
              v-for="index in paginationData.lastPage"
              :key="index"
              @click="setAssetsData(index)"
              :class="
                paginationData.currentPage == index ? 'active' : 'not-active'
              "
            >
              {{ index }}
            </button>
          </div>

          <button
            class="prev-next next"
            @click="setAssetsData(paginationData.currentPage + 1)"
            :disabled="paginationData.currentPage === paginationData.lastPage"
          >
            {{ $t("cards.next") }}

            <unicon
              name="angle-left"
              fill="#ffffff"
              height="18"
              width="18"
            ></unicon>
          </button>
        </div>
      </div> -->

      <div
        class="load-more"
        v-if="lists.dataSet.length > 0 && this.paginationData.lastPage > 1"
      >
        <div class="load-more-btns">
          <button
            class="prev-next prev"
            @click="setAssetsData(paginationData.currentPage - 1)"
            :disabled="paginationData.currentPage === 1"
          >
            <unicon
              name="angle-right"
              fill="#ffffff"
              height="18"
              width="18"
            ></unicon>
            {{ $t("cards.previous") }}
          </button>
          <div class="btns-body">
            <!-- <button
              v-for="index in paginationData.lastPage"
              :key="index"
              @click="setAssetsData(index)"
              :class="
                paginationData.currentPage == index ? 'active' : 'not-active'
              "
            >
              {{ index }}
            </button> -->

            <button
              @click="setAssetsData(paginationData.currentPage - 1)"
              :class="paginationData.currentPage == 0 ? 'active' : 'not-active'"
              v-if="paginationData.currentPage - 1 > 0"
            >
              {{ paginationData.currentPage - 1 }}
            </button>

            <button
              @click="setAssetsData(paginationData.currentPage)"
              class="active"
            >
              {{ paginationData.currentPage }}
            </button>
            <!-- Next Page -->
            <button
              @click="setAssetsData(paginationData.currentPage + 1)"
              v-if="paginationData.currentPage + 1 <= paginationData.lastPage"
            >
              {{ paginationData.currentPage + 1 }}
            </button>

            <span class="pagination-dots">....</span>
            <!-- Last Page -->
            <button
              @click="setAssetsData(paginationData.lastPage)"
              v-if="paginationData.lastPage > paginationData.currentPage + 1"
            >
              {{ paginationData.lastPage }}
            </button>
          </div>

          <button
            class="prev-next next"
            @click="setAssetsData(paginationData.currentPage + 1)"
            :disabled="paginationData.currentPage === paginationData.lastPage"
          >
            {{ $t("cards.next") }}

            <unicon
              name="angle-left"
              fill="#ffffff"
              height="18"
              width="18"
            ></unicon>
          </button>
        </div>
      </div>
    </div>
    <!-- <pagination v-model="page" :records="500" :per-page="25" @paginate="setAssetsLandPage"></pagination> -->
  </div>
</template>

<script>
import EstateModal from "./EstateModal.vue";
import NotLoggedModal from "./NotLoggedModal.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import { mapGetters, mapActions } from "vuex";

// https://dashbord-panel.aldabaan.sa/api/like_asset
var numeral = require("numeral");

export default {
  data() {
    return {
      center: {
        lat: 15.5970502,
        lng: 32.5583356,
        z: 40,
      },
      page: 1,
      order_asset: "",
      lastPage: undefined,
      currentPage: 1,
      pageButtons: [],
      justKey: 1,
    };
  },

  computed: {
    ...mapGetters("EstateIndex", [
      "lists",
      "info",
      "loading",
      "paginationData",
      "markers",
      "f_loading",
    ]),

    ...mapGetters("UserIndex", ["user_id", "user", "baseUrl"]),

    checkData() {
      if (
        (this.lists.dataSet.length == 0 && this.loading) ||
        (this.lists.dataSet.length == 0 &&
          !this.loading &&
          !this.info.noDataFound)
      ) {
        return true;
      } else return false;
    },
  },

  components: {
    "loading-overlay": Loading,
  },

  methods: {
    ...mapActions("EstateIndex", [
      "getAssetTypes",
      "setAssetsLandPage",
      "setRegions",
      "getAllcities",
      "getDistricts",
      "setAssetCordinate",
      "setLoading",
      "performSearch",
    ]),

    scrollToTop() {
      const element = document.getElementById("content");
      element.scrollIntoView(true);
    },

    setAssetsData(page) {
      if (this.info.searchMode)
        this.performSearch(page).then(() => {
          this.scrollToTop();
        });
      else
        this.setAssetsLandPage(page).then(() => {
          this.scrollToTop();
        });
    },

    ...mapActions("UserIndex", ["dislikeAsset", "likeAsset"]),

    getColor(item) {
      if (item.offer_type == "بيع") return "sale";
      else if (item.offer_type == "إيجار") return "yellow";
      else return "offer";
    },

    // timeOnApp(item){
    //     if(item.spendedDays.type == 1){
    //         if(item.spendedDays.value <= 2)
    //           return item.spendedDays.value + '' + i18n("cards.sortBy")
    //     }
    //     switch(item.spendedDays.type){
    //       case 1 : return item.spendedDays.v
    //     }
    // },
    handleHover(id) {
      const hoveredMarker = this.markers.find((marker) => marker.id == id);
      window.google.maps.event.trigger(hoveredMarker, "mouseover");
    },
    handleLeave(id) {
      window.google.maps.zoom = 20;
      const hoveredMarker = this.markers.find((marker) => marker.id == id);
      window.google.maps.event.trigger(hoveredMarker, "mouseout");
    },

    showLoggedModal() {
      this.$modal.show(
        NotLoggedModal,
        {},
        {
          adaptive: true,
          height: "auto",
          scrollable: false,
        }
      );
    },
    hideLoggedModal() {
      this.$modal.hide("not-logged-modal");
    },
    performLike(item) {
      if (this.user) {
        this.likeAsset(item.id);
      } else {
        this.showLoggedModal();
      }
    },

    showDetailsModal(item) {
      var screenWidth = window.screen.width;

      this.$router.push({
        path: "/details/" + item.id,
      });

      this.$modal.show(
        EstateModal,
        {},
        {
          width: screenWidth < 992 ? "100%" : "1200px",
          height: "auto",
          scrollable: false,
        },

        {
          "before-close": () => {
            this.$router.push({
              path: "/",
            });
          },
        }
      );
    },

    formatNumber(value) {
      return numeral(value).format("0,0") + " رس";
    },

    getRoomsPropertyCount(item) {
      if (item.properties != null)
        for (let index = 0; index < item.properties.length; index++) {
          if (
            item.properties[index].name == "عدد الغرف" &&
            item.properties[index].pivot != null
          )
            return item.properties[index].pivot.value;
        }

      return 0;
    },

    getPathRoomsCount(item) {
      if (item.properties != null)
        for (let index = 0; index < item.properties.length; index++) {
          if (
            item.properties[index].name == "عدد الحمامات" &&
            item.properties[index].pivot != null
          )
            return item.properties[index].pivot.value;
        }

      return 0;
    },

    orderBy(page = 1) {
      var order_by = "";
      var order_type = "";

      switch (this.order_asset) {
        case "اعلى الأسعار":
          order_by = "offer_price";
          order_type = "desc";
          break;
        case "اقل الأسعار":
          order_by = "offer_price";
          order_type = "asc";
          break;
        case "المساحة":
          order_by = "asset_area";
          order_type = "desc";
          break;

        case "الاحدث إضافة":
          order_by = "id";
          order_type = "desc";
          break;
        default:
          order_by = "id";
          order_type = "desc";
      }
      this.info.order_by = order_by;
      this.info.order_type = order_type;

      if (this.info.searchMode) {
        this.performSearch(page);
      } else this.setAssetsLandPage(page);
    },
  },
};
</script>

<style lang="scss">
.filter {
  display: flex;
  align-items: center;

  .filter-select {
    margin-right: 10px;

    .formulate-input-wrapper {
      .formulate-input-element {
        select {
          border-radius: 4px;
          color: $text;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.sort-by {
  margin-right: 10px;
  padding: 4px;
  border: 1px solid #555555;
  color: $text;
}

@media only screen and (max-width: 768px) {
  .cards-side {
    padding-bottom: 40px;
  }
}

.cards-side {
  // border-top: 2px solid #ccc;
  overflow: auto;
  height: 100%;

  .top-bar {
    direction: rtl;
    padding: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.cards {
  direction: rtl;
  padding-right: 5px;

  .card-loading {
    opacity: 0.5;
  }

  .card {
    z-index: 1;
    margin-left: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;

    .card-img {
      cursor: pointer;
      position: relative;

      .top-right,
      .top-left {
        position: absolute;
        top: 0;
        background: #00000080;
        color: #fff;
        padding: 4px 12px;
      }

      .top-right {
        right: 0;

        .dot {
          display: inline-block;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          margin-right: 5px;
          background: $danger;
        }

        .dot.sale {
          background: red;
        }

        .dot.offer {
          background: #673ab7;
        }

        .dot.yellow {
          background: #ffee58;
        }
      }

      .top-left {
        left: 0;
      }

      width: 100%;
      height: 220px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .card-text {
      padding: 10px;

      .text-name {
        display: flex;
        justify-content: space-between;
        cursor: pointer;

        .name {
          font-weight: bold;
        }

        .btn {
          button {
            font-size: 14px;
            border: 1px solid $text;
            background-color: transparent;
            color: $text;
            border-radius: 3px;
            padding-left: 0;
          }
        }
      }

      .text-des {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        color: $text;
        font-size: 14px;
      }
    }

    .card-footer {
      background-color: #f7f7f7;
      padding: 10px;
      display: flex;
      justify-content: space-between;

      .features {
        display: flex;
        align-items: center;

        .feature {
          display: flex;
          align-items: center;
          margin-left: 10px;

          .feature-icon {
            width: 25px;
            height: 25px;

            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }

      .like-share {
        display: flex;
        direction: rtl;

        .like,
        .share {
          width: 30px;
          height: 30px;
          cursor: pointer;
          direction: rtl;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .share {
          margin-left: 10px;
          position: relative;

          .tooltip-social-icons {
            // width: 100px;
            // height: 50px;

            img {
              margin-right: 10px;
              width: 30px;
              height: 30px;
            }
          }
        }
      }
    }
  }
}

.hsy-tooltip > .tooltip {
  color: #fff;
  background-color: darkblue !important;
}

.load-more {
  margin-top: 10px;
  margin-bottom: 20px;
  text-align: center;

  .load-more-btns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btns-body {
    margin-right: 10px;
    margin-left: 10px;
  }

  .btns-body {
    button {
      background-color: transparent;
      border: none;
      border: 1px solid $primary;
      margin-right: 4px;
      padding: 5px 15px;
      border-radius: 4px;
    }
  }

  button.active {
    background-color: $primary;
    color: white;
  }

  .prev-next {
    background-color: $primary;
    color: white;
    border-radius: 5px;
    border: none;

    &:disabled {
      opacity: 0.8;
    }
  }

  .prev {
    padding: 7px 7px 7px 15px;
  }

  .next {
    padding: 7px 15px 7px 7px;
  }
}

.empty-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  h3 {
    margin-top: 20px;
  }

  p {
    color: $text;
  }

  img {
    width: 300px;
  }
}
</style>
