<template>
  <section class="mobile-nav">
    <div class="marquee-container" v-if="info.showMovedText.show">
      <VTextMarquee :speed="50" :animate="true" class="moving-text">
        <h4
          v-for="(item, index) in lists.moved_text"
          :key="item.id"
          class="moved-text"
        >
          {{ item.content }}
          <span
            class="moved-text-img"
            v-if="!(lists.moved_text.length == index + 1)"
          >
            <img src="@/assets/logos/LOGO1.png" alt="" />
          </span>
        </h4>
      </VTextMarquee>
    </div>

    <div class="mobile-nav-container">
      <div class="nav-body mobile">
        <div class="bars" @click="toggleMobileMenu" id="menu-bars">
          <unicon name="bars" fill="#1B47EA" height="35" width="35"></unicon>
        </div>
        <div class="logo" @click="pushToHome">
          <img src="@/assets/logos/logoSmall.svg" alt="" />
        </div>
        <div class="mobile-search">
          <form @submit.prevent="submitSearch">
             <FormulateInput
              v-model="searchValues.asset.id"
              type="number"
              placeholder="رقم العقار"
            
            />
          </form>
         
        </div>
        <div class="mobile-sign-in" v-if="!user">
          <button
            @click="
              $router.push({
                path: '/login',
              })
            "
          >
            {{ $t("navBar.signIn") }}
          </button>
        </div>

        <div class="mobile-sign-in" v-else>
          <button class="btn-white btn-sign-in" @click="acountBox = !acountBox">
            <span>
              <unicon
                name="user"
                fill="#1B47EA"
                height="18"
                width="18"
              ></unicon>
            </span>
            {{ $t("navBar.myAccount") }}
          </button>
          <div class="drop-down" v-if="acountBox" id="drop_down">
            <div class="drop-down-body">
              <ul>
                <li @click="searchByUserFav(user.id)">
                  <span class="text"> قائمة المفضلة </span>

                  <span class="icon">
                    <unicon
                      name="heart-sign"
                      fill="#F44336"
                      height="18"
                      width="18"
                    ></unicon>
                  </span>
                </li>

                <li @click="locatorUserPosition">
                  <span class="text"> موقعي الحالي </span>

                  <span class="icon">
                    <unicon
                      name="map-marker"
                      fill="#1b47ea80"
                      height="18"
                      width="18"
                    ></unicon>
                  </span>
                </li>

                <hr />

                <li @click.prevent="performLogout">
                  <router-link to="/"
                    ><span class="icon"> </span
                    ><span class="text"> تسجيل خروج</span>
                    <unicon
                      name="sign-out-alt"
                      fill="#555555"
                      height="18"
                      width="18"
                    ></unicon>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="tablet-nav-container">
      <div class="nav-body tablet">
        <div class="bars" @click="toggleMobileMenu" id="menu-bars">
          <unicon name="bars" fill="#1B47EA" height="35" width="35"></unicon>
        </div>

        <div class="logo">
          <img src="@/assets/logos/logoHome.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="mobile-menu hide" id="mobile-menu">
      <div class="bars" @click="toggleMobileMenu" id="menu-bars">
        <unicon name="times" fill="#fff" height="35" width="35"></unicon>
      </div>
      <ul>
        <li>
          <router-link to="/" @click.native="toggleMobileMenu">
            {{ $t("navBar.home") }}
          </router-link>
        </li>
        <li>
          <router-link to="/about" @click.native="toggleMobileMenu">
            {{ $t("navBar.about") }}
          </router-link>
        </li>
        <li>
          <router-link to="/contact" @click.native="toggleMobileMenu">
            {{ $t("navBar.contact") }}
          </router-link>
        </li>
        <li>
          <router-link to="/add" @click.native="showModalAdd">
            اضافة عقار
          </router-link>
        </li>
        <li>
          <router-link to="/join" @click.native="toggleMobileMenu">
            {{ $t("navBar.join") }}
          </router-link>
        </li>
        <li>
          <router-link to="/rate" @click.native="toggleMobileMenu">
            {{ $t("navBar.rates") }}
          </router-link>
        </li>
      </ul>
    </div>
    <div class="container">
      <MobileSearchBar />
    </div>
  </section>
</template>

<script>
import AddEstateModal from "./AddEstateModal";
import MobileSearchBar from "./MobileSearchBar";
import { VTextMarquee } from "vue-text-marquee";

import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      acountBox: false,
      favoritesBox: false,
    };
  },
  computed: {
    ...mapGetters("UserIndex", ["user_id", "user"]),
    ...mapGetters("EstateIndex", ["searchValues", "lists", "info"]),
  },
  components: {
    MobileSearchBar,
    VTextMarquee,
  },

  methods: {
    ...mapActions("EstateIndex", [
      "performSearch",
      "locatorUserPosition",
      "searchByUserFav",
    ]),
    pushToHome() {
      window.location.reload();
    },
    submitSearch(){
       this.performSearch().then(() => {
            this.searchValues.asset = {}
       });

    },
    toggleMobileMenu() {
      var element = document.getElementById("mobile-menu");
      element.classList.toggle("hide");
    },
    showAcountBox() {
      this.acountBox = true;
    },

    onClickOutside() {
      this.favoritesBox = false;
    },

    performLogout() {
      localStorage.removeItem("deb-us");
      localStorage.removeItem("deb-to");
      this.$router.go("/");
    },
    showModalAdd() {
      this.toggleMobileMenu();
      var screenWidth = window.screen.width;
      // this.$router.push({ path: "/add" });

      this.$modal.show(
        AddEstateModal,
        {},
        {
          width: screenWidth < 992 ? "100%" : "900px",
          height: "auto",
          scrollable: true,
        },

        {
          "before-close": () => {
            this.$router.push({
              path: "/",
            });
          },
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-nav {
  border-bottom: 1px solid #ccc;
  //border-top: 1px solid #ccc;
}
.moving-text {
  color: $text;
  background-color: white;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  direction: ltr;
  font-size: 14px;
}
.moved-text {
  display: inline-block;
  margin-left: 10px;
  margin-bottom: 0;
  color: #757575;
  font-size: 14px;
}
.moved-text-img {
  img {
    height: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.mobile-nav-container {
  display: none;
  border-bottom: 1px solid #ccc;
  
}

@media only screen and (max-width: 768px) {
  .mobile-nav-container {
    display: block !important;
  }

  .tablet-nav-container {
    display: none !important;
  }
  
@media only screen and (min-width: 768px) {
  .marquee-container {
  display: none !important;
}
}
}

@media (max-width: 992px) {
  .mobile-nav-container {
    display: none;
  }

  .tablet-nav-container {
    display: block;
  }
}

.nav-body.tablet {
  padding: 10px 5px;
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;

  .logo {
    direction: rtl;
    text-align: start;

    img {
      padding: 10px;
      width: 45%;
    }
  }
}

.nav-body.mobile {
  padding: 10px 5px;
  direction: ltr;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .bars,
  .logo,
  .mobile-search {
    margin-right: 5px;
    direction: rtl;
  }
  .mobile-search {
    width: 100%;
    padding: 5px 10px;
  }

  .logo {
    height: 30px;
    width: 30px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .mobile-sign-in {
    button {
      background-color: transparent;
      color: $primary;
      border: none;
    }
  }
}

.mobile-menu {
  background: $primary;
  color: white;
  z-index: 999;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  padding: 20px;

  ul {
    list-style-type: none;
    text-align: center;
    padding: 0;

    li {
      margin-bottom: 20px;

      a {
        color: white;
        width: 100%;
      }
    }
  }
}

.hide {
  display: none;
}

.drop-down {
  min-width: 0;
  right: 0px;
  top: 62px;

  ul {
    padding: 0;
  }

  li {
    list-style-type: none;
  }
}
</style>
